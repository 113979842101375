import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Col } from "react-bootstrap";
import { BsArrowLeft } from "react-icons/bs";
import { TextField } from "@mui/material";
import DataService from "../../../services/requestApi";
import Swal from "sweetalert2";
const AddSubClass = ({
  classData,
  concept,
  Department,
  open,
  setaddSubClass,
  GetSubReport,
}) => {
  const { saasId, storeId } = JSON.parse(localStorage.getItem("User_data"));
  const [ConName, setConName] = useState({
    saas_id: saasId,
    store_id: storeId,
    class_code: "",
    class_name: "",
    dept_code: "",
    dept_name: "",
    con_code: "",
    con_name: "",
    sub_class_name: "",
    sub_class_id: "",
  });
  useEffect(() => {
    setConName({ ...ConName, saas_id: saasId, store_id: storeId });
  }, [saasId, storeId]);
  //api call for update name
  const UpadteConcept = async () => {
    try {
      console.log("ConName", ConName);
      if (
        ConName?.class_code &&
        ConName?.con_code &&
        ConName?.dept_code &&
        ConName?.sub_class_id &&
        ConName?.sub_class_name
      ) {
        const response = await DataService.AddSubClass(ConName);
        console.log("new respons", response.data);
        if (response.data.status) {
          GetSubReport();
          setaddSubClass(false);
          setConName({
            saas_id: saasId,
            store_id: storeId,
            class_code: "",
            class_name: "",
            dept_code: "",
            dept_name: "",
            con_code: "",
            con_name: "",
            sub_class_name: "",
            sub_class_id: "",
          });
          Swal.fire({
            title: "SubClass Added Successfully",
            icon: "success",
            timer: 2000,
          });
        }
      } else {
        Swal.fire({
          title: "Please Fill All Feild",
          icon: "info",
          timer: 2000,
        });
      }
    } catch (error) {
      console.log("this is catch", error);
    }
  };

  const HandleChange = (e) => {
    setConName({ ...ConName, [e.target.name]: e.target.value });
  };
  return (
    <div>
      <Modal isOpen={open} toggle={() => setaddSubClass(!open)}>
        <ModalHeader>
          <BsArrowLeft
            onClick={() => setaddSubClass(!open)}
            className="mouse-pointer"
          />{" "}
          Add SubClass *
        </ModalHeader>
        <ModalBody>
          <div className="row d-flex justify-content-center">
            <div className="">
              <form
                className="form-box"
                // onSubmit={handleAddItem}
                // encType="multipart/form-data"
                encType="Content-Type"
              >
                <div
                  className="d-flex flex-col"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <TextField
                    select
                    size="small"
                    className="form-control my-2"
                    value={ConName?.class_code || ""}
                    name="class_name"
                    onChange={(e) => {
                      const selectedOption = e.target.value;
                      // Find the selected option from the array of options
                      const selected = classData.find(
                        (el) => el.class_id == selectedOption
                      );
                      if (selected) {
                        console.log(
                          "Selected Class",
                          selected,
                          selectedOption,
                          concept
                        );
                        setConName({
                          ...ConName,
                          class_name: selected.class_name,
                          class_code: selected.class_id,
                        });
                      }
                    }}
                    required
                    SelectProps={{
                      native: true,
                    }}
                  >
                    <option value="">Select Class</option>
                    {classData &&
                      classData?.map((el) => (
                        <option key={el.class_id} value={el.class_id}>
                          {el.class_name}
                        </option>
                      ))}
                  </TextField>

                  <TextField
                    select
                    size="small"
                    className="form-control my-2"
                    value={ConName?.con_code || ""}
                    name="conc_name"
                    onChange={(e) => {
                      const selectedOption = e.target.value;
                      // Find the selected option from the array of options
                      const selected = concept.find(
                        (el) => el.conc_code == selectedOption
                      );
                      if (selected) {
                        console.log(
                          "Selected Concept",
                          selected,
                          selectedOption,
                          concept
                        );
                        setConName({
                          ...ConName,
                          con_name: selected.conc_name,
                          con_code: selected.conc_code,
                        });
                      }
                    }}
                    required
                    SelectProps={{
                      native: true,
                    }}
                  >
                    <option value="">Select Concept</option>
                    {concept &&
                      concept?.map((el) => (
                        <option key={el.conc_code} value={el.conc_code}>
                          {el.conc_name}
                        </option>
                      ))}
                  </TextField>
                  <TextField
                    select
                    size="small"
                    className="form-control my-2"
                    value={ConName?.dept_code || ""}
                    name="dept_name"
                    onChange={(e) => {
                      const selectedOption = e.target.value;
                      // Find the selected option from the array of options
                      const selected = Department.find(
                        (el) => el.dept_code == selectedOption
                      );
                    
                      if (selected) {
                        console.log(
                          "Selected department",
                          selected,
                          selectedOption,
                          Department
                        );
                        setConName({
                          ...ConName,
                          dept_name: selected.dept_name,
                          dept_code: selected.dept_code,
                        });
                      }
                    }}
                    required
                    SelectProps={{
                      native: true,
                    }}
                  >
                    <option value="">Select Department</option>
                    {Department &&
                      Department?.map((el) => (
                        <option key={el.dept_code} value={el.dept_code}>
                          {el.dept_name}
                        </option>
                      ))}
                  </TextField>
                  <TextField
                    size="small"
                    type="text"
                    className="form-control my-2"
                    value={ConName?.sub_class_id}
                    name="sub_class_id"
                    onChange={HandleChange}
                    label="sub_class_id"
                    multiline
                    required
                    rows={1}
                  />
                  <TextField
                    size="small"
                    type="text"
                    className="form-control my-2"
                    value={ConName?.sub_class_name}
                    name="sub_class_name"
                    onChange={HandleChange}
                    label="Sub ClassName"
                    multiline
                    required
                    rows={1}
                  />
                  {/* <TextField
                size="small"
                type="text"
                className="form-control my-2"
                // value={itemPrice}
                // required
                // onChange={(e) => setItemPrice(e.target.value)}
                label="Item Price"
              />
              <TextField
                size="small"
                type="text"
                className="form-control my-2"
                // value={hsnCode && "0"}
                // required
                // onChange={(e) => setHsnCode(e.target.value)}
                label="HSN Code"
              />

              <TextField
                size="small"
                type="text"
                className="form-control my-2"
                // value={taxPercentage}
                // required
                // onChange={(e) => setTaxPercentage(e.target.value)}
                label="Tax Percentage"
              />
              <TextField
                size="small"
                type="text"
                className="form-control my-2"
                // value={purchasePrice}
                // required
                // onChange={(e) => setPurchasePrice(e.target.value)}
                label="Purchase Price"
              />
              <TextField
                size="small"
                type="text"
                className="form-control my-2"
                // value={openingQuantity}
                // required
                // onChange={(e) => setOpeningQuantity(e.target.value)}
                label="Opening Quantity"
              />
              <TextField
                size="small"
                type="text"
                className="form-control my-2"
                // value={receivedQuantity}
                // required
                // onChange={(e) => setReceivedQuantity(e.target.value)}
                label="Received Quantity"
              />
             
              <TextField
                size="small"
                type="text"
                className="form-control my-2"
                // value={pcs_price}
                // onChange={(e) => setPcs_Price(e.target.value)}
                label="Enter Price Per Pice"
              /> */}
                </div>

                <div className="">
                  <button
                    type="button"
                    style={{
                      backgroundColor: "rgb(46, 69, 175)",
                      outline: "none",
                      border: "none",
                      fontSize: "20px",
                      padding: "10px 20px",
                      borderRadius: "10px",
                      color: "#fff",
                    }}
                    onClick={UpadteConcept}
                  >
                    Add SubClass
                  </button>

                  <span
                    // to="/retailer-dashboard"
                    onClick={() => {
                      setaddSubClass(!open);
                    }}
                    className="btn btn-primary"
                    style={{
                      backgroundColor: "grey",
                      outline: "none",
                      border: "none",
                      marginLeft: "20px",
                      fontSize: "20px",
                      padding: "10px 20px",
                      borderRadius: "10px",
                      color: "#fff",
                    }}
                  >
                    Close
                  </span>
                </div>
              </form>
              {/* )} */}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>{/* <h1>FOOTER</h1> */}</ModalFooter>
      </Modal>
    </div>
  );
};

export default AddSubClass;
