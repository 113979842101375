import React, { useEffect, useState } from "react";
import { BASEURL } from "../../services/http-common";
// import { Button } from 'react-bootstrap';
import moment from "moment";
import { Button, Card, CardBody } from "reactstrap";
import { Form } from "react-bootstrap";
import Swal from "sweetalert2";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import DataService from '../../services/requestApi'
const BossReport = () => {
  const [businessDate, setBusinessDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const { saasId } = JSON.parse(localStorage.getItem("User_data")) || {};
  const [storeid, setStoreId] = useState('')
      const [stores, setStores] = useState([]);
  const downloadCSV = async (URL, name) => {
    try {
      if (!storeid) {
        Swal.fire({
          title: "Please Selecte Store Id",
          icon: "warning",
        });
        return;
      }
      // Set downloading state to true to show loading indicator
      //   this.setState({ downloading: true });

      // Replace the API URL with the actual URL
      const response = await fetch(URL);
      console.log(response);
      // Check if response is successful
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const csvData = await response.text();

      // Create a blob object from CSV data
      const blob = new Blob([csvData], { type: "text/xlsx" });

      // Create a URL for the blob object
      const url = window.URL.createObjectURL(blob);

      // Create a link element
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${storeid}_${businessDate}${name}.xlsx`);

      // Append the link to the body and click it
      document.body.appendChild(link);
      link.click();

      // Clean up resources
      window.URL.revokeObjectURL(url);

      // Reset downloading state
      //   this.setState({ downloading: false });
    } catch (error) {
      console.error("There was a problem with your fetch operation:", error);
      // Reset downloading state
      //   this.setState({ downloading: false });
    }
  };


   const getStoreid=async ()=>{
      try {
        const response = await DataService.GetStoreBySaasid(saasId)
        console.log(response.data.data)
        setStores(response.data.data)
      } catch (error) {
        console.log(error)
      }
    }

    useEffect(() => {
      getStoreid()
    }, [])
    

  return (
    <Card>
      <CardBody>
        <div className="d-flex" style={{ marginBottom: "20px" }}>
          <label
            className="mt-2"
            htmlFor="fileInput"
            style={{ fontSize: "16px", marginRight: "10px" }}
          >
            {/* {fileFlag} */}
            Download Csv:
          </label>
          <input
            type="date"
            id="csvfile"
            value={businessDate}
            onChange={(e) => {
              setBusinessDate(e.target.value);
            }}
            style={{
              border: "1px solid #ccc",
              padding: "8px",
              borderRadius: "4px",
            }}
          />
          <FormControl>
            <InputLabel id="isdcode-label" style={{ width: "fit-content" }}>
              Select Store
            </InputLabel>
            <Select
              label="Select Store"
              labelId="isdcode-label"
              id="store_id"
              name="store_id"
              value={storeid}
              onChange={(e) => setStoreId(e.target.value)}
            >
              <MenuItem value="">
                <em>Select store_id</em>
              </MenuItem>
              {stores &&
                stores.map((el) => (
                  <MenuItem key={el.store_id} value={el.store_id}>
                    {el.store_id}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          {/* <div className='my-auto'>
            <Button
            disabled={csvFile?false:true}
            className=' ml-4'
                type='button'
                onClick={(e) => handleUploadFile()}
                style={{marginLeft:"30px", backgroundColor: "rgb(46, 69, 175)", border: "none" }}> Submit </Button>
        </div> */}
        </div>

        <div className="d-flex">
          <Button
            onClick={() => {
              downloadCSV(
                `${BASEURL.ENDPOINT_URL}/csv/itemreport/${businessDate}/${storeid}`,
                "ItemReport"
              );
            }}
            className=" mr-4"
            type="button"
          >
            Download Item Report Csv
          </Button>
          <Button
            className="mx-3"
            onClick={() => {
              downloadCSV(
                `${BASEURL.ENDPOINT_URL}/csv/sfile/${businessDate}/${storeid}`,
                "Header"
              );
            }}
            type="button"
          >
            Download Header Report Csv
          </Button>
          <Button
            onClick={() => {
              downloadCSV(
                `${BASEURL.ENDPOINT_URL}/csv/tenderreport/${businessDate}/${storeid}`,
                "Tender"
              );
            }}
            className=" mr-4"
            type="button"
          >
            Download Tender Report Csv
          </Button>
        </div>
      </CardBody>
    </Card>
  );
};

export default BossReport;
