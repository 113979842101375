import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {  Form, Container, Row, Col, Alert } from "react-bootstrap";
import CustomNavbar from "../../../UiComponents/CustomNavbar";
import { styled } from "@mui/material/styles";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import DataService from "../../../services/requestApi";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Button } from "@mui/material";
import { BASEURL } from "../../../services/http-common";
import axios from "axios";
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const AddItemForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [concept, setConcept] = useState([]);
  const [Department, setDepartment] = useState([]);
  const [SubClass, setSubClass] = useState([]);
  const [Class, setClass] = useState([]);
  const [Category, setCategory] = useState([]);
  const [Taxes, setTaxes] = useState([]);
  const [snackbar, setSnackbar] = useState({
    show: false,
    message: "",
    variant: "success",
  });
  const [image , setImage] = useState(null)
  const { saasId, storeId } = JSON.parse(localStorage.getItem("Store_data"));
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      item_name: "",
      item_code: "",
      price: 0,
      actual_price:0,
      special_description: "",
      opening_qty: 0,
      product_cost: 0,
      tax: 0,
      saas_id: saasId,
      store_id: storeId,
      category: "",
      barcode: "",
      conc_id: "",
      dept: "",
      item_class: "",
      sub_class: "",
      received_qty: 0,
    },
  });

  const onSubmit = async (values) => {
    setIsLoading(true);
    try {
      const response = await DataService.AddNewItem(values);

      if (!response.data.status) {
        setSnackbar({
          show: true,
          message: response.data?.message,
          variant: "danger",
        });
        return;
      }
      handleImageUpload(response.data.data)
      setSnackbar({
        show: true,
        message: `Item ${values.item_name} has been added successfully.`,
        variant: "success",
      });
      reset({
        ...values, // Retain the current form values
        store_id: storeId, // Explicitly set the desired values
        saas_id: saasId,
      });
    } catch (error) {
      setSnackbar({
        show: true,
        message: error?.response.data?.message,
        variant: "danger",
      });
    } finally {
      setIsLoading(false);
    }
  };
  
  const handleImageUpload = (id) => {
    const data = new FormData();
    data.append("file", image);
    // data.append("item_id", selectedRow.item_id);
    axios
      .post(`${BASEURL.ENDPOINT_URL}/item/save-image2/${id}`, data)
      .then((res) => {
        console.log("Image uploaded", res);
        // setUploadModalShow(false);
        // GetItemReport();
      })
      .catch((err) => {
        console.log("ERR UPLOAD IMAGE", err);
      });
  }

  
  const GetConcept = async()=>{
    try {
        const response = await DataService.GetConceptAll(saasId);
        console.log("Concept",response.data.data)
        setConcept(response.data.data)
    } catch (error) {
        console.error(error);
    }
  }
  const getDepartment = async()=>{
    try {
        const response = await DataService.DeptMaterGet(saasId);
        console.log("Department",response.data.data)
        setDepartment(response.data.data)
    } catch (error) {
        console.error(error);
    }
  }

  const GetSubClass = async()=>{
    try {
        const response = await DataService.GetSubClass(1,100,saasId);
        console.log("SubClass",response.data.data)
        setSubClass(response.data.data)
    } catch (error) {
        console.error(error);
    }
  }

  const GetClass = async()=>{
    try {
        const response = await DataService.GetAllClassMaster(1,100,saasId);
        console.log("Class",response.data.data)
        setClass(response.data.data)
    } catch (error) {
        console.error(error);
    }
  }

  const GetCategory = async()=>{
    try {
        const response = await DataService.GetSubCategory(saasId,storeId);
        console.log("Category",response.data.data)
        setCategory(response.data.data)
    } catch (error) {
        console.error(error);
    } 
  }

  const GetTaxes = async()=>{
    try {
        const response = await DataService.GetTaxes();
        console.log("Taxes",response.data.data)
        setTaxes(response.data.data)
    } catch (error) {
        console.error(error);
    }
  }


    useEffect(() => {
        GetConcept();
        getDepartment();
        GetSubClass();
        GetClass();
        GetCategory();
        GetTaxes();
    }, [])
    


  return (
    <>
      <CustomNavbar />
      <Container>
  <h3
    style={{ cursor: "pointer" }}
    onClick={() => navigate(-1)}
    className="mt-4 mb-4"
  >
    <BiArrowBack /> Add Item
  </h3>
  {snackbar.show && (
    <Alert
      variant={snackbar.variant}
      onClose={() => setSnackbar({ ...snackbar, show: false })}
      dismissible
    >
      {snackbar.message}
    </Alert>
  )}
  <Form onSubmit={handleSubmit(onSubmit)}>
    {/* Basic Information Section */}
    <section className="mb-4">
      <h5>Basic Information</h5>
      <Row className="mb-3">
        <Col md={4}>
          <Controller
            name="item_name"
            control={control}
            rules={{ required: "Item name is required" }}
            render={({ field }) => (
              <Form.Group>
                <Form.Label>Item Name</Form.Label>
                <Form.Control
                  {...field}
                  type="text"
                  isInvalid={!!errors.item_name}
                  placeholder="Enter item name"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.item_name?.message}
                </Form.Control.Feedback>
              </Form.Group>
            )}
          />
        </Col>
        <Col md={4}>
          <Controller
            name="item_code"
            control={control}
            rules={{ required: "Item code is required" }}
            render={({ field }) => (
              <Form.Group>
                <Form.Label>Item Code</Form.Label>
                <Form.Control
                  {...field}
                  type="text"
                  isInvalid={!!errors.item_code}
                  placeholder="Enter item code"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.item_code?.message}
                </Form.Control.Feedback>
              </Form.Group>
            )}
          />
        </Col>
        <Col md={4}>
              <Controller
                name="barcode"
                control={control}
                render={({ field }) => (
                  <Form.Group>
                    <Form.Label>Barcode</Form.Label>
                    <Form.Control
                      {...field}
                      type="text"
                      placeholder="Enter barcode"
                    />
                  </Form.Group>
                )}
              />
              
            </Col>
            <Col md={4}>
          <Controller
            name="actual_price"
            control={control}
            rules={{
              required: "MRP Rate is required",
              min: { value: 0.01, message: "MRP Rate must be positive" },
            }}
            render={({ field }) => (
              <Form.Group>
                <Form.Label>MRP Rate</Form.Label>
                <Form.Control
                  {...field}
                  type="number"
                  isInvalid={!!errors.actual_price}
                  placeholder="Enter price"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.actual_price?.message}
                </Form.Control.Feedback>
              </Form.Group>
            )}
          />
        </Col>
        <Col md={4}>
          <Controller
            name="price"
            control={control}
            rules={{
              required: "Price is required",
              min: { value: 0.01, message: "Price must be positive" },
            }}
            render={({ field }) => (
              <Form.Group>
                <Form.Label>Price</Form.Label>
                <Form.Control
                  {...field}
                  type="number"
                  isInvalid={!!errors.price}
                  placeholder="Enter price"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.price?.message}
                </Form.Control.Feedback>
              </Form.Group>
            )}
          />
        </Col>
        
        <Col md={4}>
          <Controller
            name="product_cost"
            control={control}
            render={({ field }) => (
              <Form.Group>
                <Form.Label>Product Cost</Form.Label>
                <Form.Control
                  {...field}
                  type="number"
                  placeholder="Enter product cost"
                />
              </Form.Group>
            )}
          />
        </Col>
      </Row>
      
    </section>

    {/* Additional Details Section */}
    <section className="mb-4">
      <h5>Additional Details</h5>
      <Row className="mb-3">
        <Col md={4}>
          <Controller
            name="special_description"
            control={control}
            render={({ field }) => (
              <Form.Group>
                <Form.Label>Special Description</Form.Label>
                <Form.Control
                  {...field}
                  as="textarea"
                  placeholder="Enter special description"
                />
              </Form.Group>
            )}
          />
        </Col>
        <Col md={4}>
          <Controller
            name="opening_qty"
            control={control}
            render={({ field }) => (
              <Form.Group>
                <Form.Label>Opening Quantity</Form.Label>
                <Form.Control
                  {...field}
                  type="number"
                  placeholder="Enter opening quantity"
                />
              </Form.Group>
            )}
          />
        </Col>
        <Col md={4}>
              <Controller
                name="received_qty"
                control={control}
                render={({ field }) => (
                  <Form.Group>
                    <Form.Label>Received Quantity</Form.Label>
                    <Form.Control
                      {...field}
                      type="number"
                      placeholder="Enter received quantity"
                    />
                  </Form.Group>
                )}
              />
            </Col>
        
      </Row>
      <Row className="mb-3">
            <Col md={4}>
              <Controller
                name="conc_id"
                control={control}
                render={({ field }) => (
                  <Form.Group>
                    <Form.Label>Concept ID</Form.Label>
                    <Form.Select {...field} aria-label="Concept ID">
                      <option value="">Select Concept ID</option>
                      {/* Add your dynamic options here */}
                      {concept&& concept.map((el)=>{return(<option value={el.conc_code}>{el.conc_name}</option>)})}
                      
                    </Form.Select>
                  </Form.Group>
                )}
              />
            </Col>
            <Col md={4}>
              <Controller
                name="dept"
                control={control}
                render={({ field }) => (
                  <Form.Group>
                    <Form.Label>Department</Form.Label>
                    <Form.Select {...field} aria-label="Concept ID">
                      <option value="">Select Department</option>
                      {/* Add your dynamic options here */}
                      {Department&& Department.map((el)=>{return(<option value={el.dept_code}>{el.dept_name}</option>)})}
                      
                    </Form.Select>
                  </Form.Group>
                )}
              />
            </Col>
            <Col md={4}>
              <Controller
                name="item_class"
                control={control}
                render={({ field }) => (
                  <Form.Group>
                    <Form.Label>Item Class</Form.Label>
                    <Form.Select {...field} aria-label="Concept ID">
                      <option value="">Select Item Class</option>
                      {/* Add your dynamic options here */}
                      {Class&& Class.map((el)=>{return(<option value={el.class_id}>{el.class_name}</option>)})}
                      
                    </Form.Select>
                  </Form.Group>
                )}
              />
            </Col>
          </Row>
    </section>

    {/* Tax and Category Section */}
    <section className="mb-4">
      <h5>Tax and Category</h5>
      <Row className="mb-3">
      <Col md={4}>
               <Controller
                name="sub_class"
                control={control}
                render={({ field }) => (
                  <Form.Group>
                    <Form.Label>SubClass</Form.Label>
                    <Form.Select {...field} aria-label="Concept ID">
                      <option value="">Select Sub Class</option>
                      {/* Add your dynamic options here */}
                      {SubClass&& SubClass.map((el)=>{return(<option value={el.sub_class_code}>{el.sub_class_name}</option>)})}
                      
                    </Form.Select>
                  </Form.Group>
                )}
              />
            </Col>
        <Col md={4}>
          <Controller
            name="tax"
            control={control}
            render={({ field }) => (
              <Form.Group>
                <Form.Label>Tax (%)</Form.Label>
                <Form.Select {...field} aria-label="Tax Type">
                  <option value="">Select Tax Type</option>
                  {Taxes &&
                    Taxes.map((el) => (
                      <option value={el.taxRate}>{el.taxCode}</option>
                    ))}
                </Form.Select>
              </Form.Group>
            )}
          />
        </Col>
        <Col md={4}>
          <Controller
            name="category"
            control={control}
            render={({ field }) => (
              <Form.Group>
                <Form.Label>Category</Form.Label>
                <Form.Select {...field} aria-label="Category">
                  <option value="">Select Category</option>
                  {Category &&
                    Category.map((el) => (
                      <option value={el.category_name}>{el.category_name}</option>
                    ))}
                </Form.Select>
              </Form.Group>
            )}
          />
        </Col>
        
            
            {/* <Row className="mb-3"> */}
        <Col md={4} className="d-flex align-items-center mt-4">
          <Button
            component="label"
            role={undefined}
            variant="contained"
            startIcon={<CloudUploadIcon />}
          >
            Upload Item Image
            <input
              type="file"
              hidden
              onChange={(event) => setImage(event.target.files[0])}
            />
          </Button>
        </Col>
      {/* </Row> */}
        {/* <Col md={4}>
          <Controller
            name="conc_id"
            control={control}
            render={({ field }) => (
              <Form.Group>
                <Form.Label>Concept ID</Form.Label>
                <Form.Select {...field} aria-label="Concept ID">
                  <option value="">Select Concept ID</option>
                  {concept &&
                    concept.map((el) => (
                      <option value={el.conc_code}>{el.conc_name}</option>
                    ))}
                </Form.Select>
              </Form.Group>
            )}
          />
        </Col> */}
      </Row>
          
    </section>

    {/* File Upload Section */}
    {/* <section className="mb-4">
      <h5>Upload</h5>
      
    </section> */}

    <Button
      variant="contained"
      color="primary"
      type="submit"
      disabled={isLoading}
    >
      {isLoading ? "Adding..." : "Add Item"}
    </Button>
  </Form>
</Container>
    </>
  );
};

export default AddItemForm;
