import moment from "moment";
import React from "react";
import Barcode from "react-barcode";
import { PrintProvider, Print } from "react-easy-print";
import { useSelector } from "react-redux";

function ReceiptModal({
  TotalAmount,
  barcodeData,
  ChangeDue,
  Tender,
  CreditId,
  newCredit,
  CutomerAmount,
  UsdAmount,
  newCoupon
}) {
  const customer = useSelector((state) => state.Reducer.LinkCustomer);
  // console.log("first", customer)
  const TotalDiscountonInvoice = useSelector(
    (state) => state.Reducer.DicountOnTotal
  );
  const displayData = useSelector((state) => state.Reducer.displayCart);
  const totalamount = displayData.reduce((sum, item) => sum + item.newPrice, 0);
  const   totalitemDis = displayData.reduce(
    (sum, item) => sum + item.discount * item.productQty,
    0
  );
  const TotalGross = parseFloat(totalamount) + parseFloat(totalitemDis);
  const totalProductQty = displayData.reduce(
    (total, item) => total + parseInt(item.productQty),
    0
  );
  const { storeName, userId, userName } = JSON.parse(
    localStorage.getItem("User_data")
  );
  const {
    haderLine1,
    haderLine2,
    haderLine3,
    haderLine4,
    futterLine1,
    futterLine2,
    futterLine3,
    futterLine4,
    futterLine5,
    futterLine6,
    futterLine7,
    futterLine8,
    futterLine9,
    futterLine10,
    saasId
  } = JSON.parse(localStorage.getItem("Store_data"));
  // const styles = {
  //   fontFamily: "Verdana",
  //   fontSize: "medium",
  //   fontWeight: "bold",
  // };
  const styles = {
    container: {
      width: "400px",
      margin: "0 auto",
      padding: "20px",
      // border: "1px solid #000",
      fontFamily: "Arial, sans-serif",
      lineHeight: 1.5,
    },
    header: {
      textAlign: "center",
      fontSize: "1.5rem",
      marginBottom: "5px",
      fontWeight: "bold",
    },
    subheader: {
      textAlign: "center",
      fontSize: "1.2rem",
      marginBottom: "10px",
    },
    address: {
      textAlign: "center",
      marginBottom: "20px",
    },
    details: {
      fontSize: "0.9rem",
      // marginBottom: "20px",
    },
    table: {
      width: "100%",
      // borderCollapse: "collapse",
      // marginBottom: "20px",
    },
    tableHeader: {
      // border: "1px solid #000",
      textAlign: "center",
      padding: "5px",
      fontSize: "0.85rem",
      fontWeight: "bold",
    },
    tableCell: {
      // border: "1px solid #000",
      textAlign: "center",
      padding: "5px",
      fontSize: "0.85rem",
    },
    summary: {
      fontSize: "0.9rem",
      // marginBottom: "20px",
    },
    footer: {
      textAlign: "center",
      fontSize: "0.85rem",
      marginTop: "10px",
    },
  };
  const customLineStyle = {
    border: "1px solid black",
    margin: "8px 0",
  };
  const table = {
    border: "1px solid black",
    margin: "8px 0",
  };
  const currentDate = moment(Date.now()).format("DD/MM/YYYY");
  const currentTime = moment(Date.now()).format("h:mm:ss a");
  
  const receiptContent = (
    <div style={styles.container}>
         {/* <h2 style={styles.header}>ANITA - M657186</h2>
         <h3 style={styles.subheader}>SAINIK FAMILY WELFARE CANTEEN - 2</h3>
         <p style={styles.address}>
          ALIGARH JEWAR ROAD, JATTARI, PARAGANA<br />
          TAPAL, TE <br />
          Phone: 9540077345<br />
          GSTIN: 09EFTPD3988C1ZY | FSSAI: 12722056000014
        </p> */}
      <p
        style={styles.header}
        className="d-flex  justify-content-around fw-bold"
      >
        {" "}
        {haderLine1}
      </p>
      <p
        style={styles.subheader}
        className="d-flex  justify-content-around fw-bold"
      >
        {" "}
        {haderLine2}
      </p>
      <p
        style={styles.address}
        className="d-flex  justify-content-around fw-bold"
      >
        {haderLine3}
      </p>
      <p
        style={styles.address}
        className="d-flex  justify-content-around fw-bold"
      >
        {haderLine4}
      </p>


        {/* {customer?.name && ( */}
    
       {/* <div className="" style={{display:"flex", justifyContent:"space-between"}} > */}
  {/* Customer Details */}
  <div className="fw-bold " style={customLineStyle}></div>
  <div>
    <div style={{display:"flex", justifyContent:"space-between"}} >
    <p className="m-0 p-0">
      <strong>Customer:</strong> <strong>{customer?.name}</strong>
    </p>
    <p className="m-0 p-0">
      <strong>Bill No:</strong>  <strong>{barcodeData}</strong> 
    </p>
    </div>
    <div style={{display:"flex", justifyContent:"space-between"}} >
    <p className="m-0 p-0">
      <strong>Mobile:</strong> <strong>{customer?.mobile_number}</strong> 
    </p>
    <p className="m-0 p-0">
      <strong>Date:</strong><strong>{currentDate}</strong> 
    </p>

    </div>
    <div style={{display:"flex", justifyContent:"space-between"}} >
    <p className="m-0 p-0">
      <strong>User:</strong> <strong>{userName}</strong> 
    </p>
    <p className="m-0 p-0">
      <strong>Time:</strong><strong>{currentTime}</strong> 
    </p>

    </div>
  </div>
  {/* Bill Details */}
  {/* <div>
    
    
    
  </div> */}
{/* </div> */}
              
        {/* )} */}
        {/* {CreditId && <span>Credit Note: {CreditId}</span>} */}
    
      <div className="fw-bold " style={customLineStyle}></div>

      <table style={styles.table}>
          <thead>
            <tr>
            <th style={styles.tableHeader}>S.</th>
             <th style={styles.tableHeader}>Description</th>
              <th style={styles.tableHeader}>Qty</th>
              <th style={styles.tableHeader}>MRP</th>
              <th style={styles.tableHeader}>Rate</th>
              <th style={styles.tableHeader}>Amt</th>
            </tr>
          </thead>
        <tbody>
          {displayData &&
            displayData.map((el,index) => {
              return (
                <>
                  {" "}
                  {el.productQty > 0 && (
                    <>
                    <tr>
                    <td style={styles.tableHeader}>{index + 1}</td>
                      <td style={styles.tableHeader}>{el.item_name.slice(0, 25)}</td>
                      <td style={styles.tableHeader}>
                          {el.productQty}
                        </td>
                        <td style={styles.tableHeader}>{el.actual_price}</td>
                        <td style={styles.tableHeader}>
                          {Math.ceil(el.price * el.productQty)}
                        </td>
                        <td style={styles.tableHeader}>
                        {Math.ceil(el.price * el.productQty)}
                        </td>
                      </tr>
                      {el.discount > 0 && (<tr >
                      {/* <td style={styles.tableHeader}>
                          {el.item_name.slice(0, 25)}
                        </td> */}
                        
                          {!saasId ==15 &&<>
                              <td style={styles.tableHeader}>
                              Disc {el?.Discountper && Math.floor(el.Discountper)}
                              {el?.Discountper && "%"}
                            </td>
                            <td style={styles.tableHeader}>{Math.floor(el.discount * el.productQty)}</td>{" "}
                          </>}
                        
                      </tr>)}
                    </>
                  )}
                </>
              );
            })}
        </tbody>
      </table>

      <div className="fw-bold" style={customLineStyle}></div>

      <div style={styles.summary}>
        <div className="d-flex justify-content-between fw-bold text-nowrap">
          {/* <div className="col"> */}
          <p className="m-0">Item Qty: {totalProductQty}</p>
          {/* </div> */}
          {/* <div className="col"> */}
          
          {/* </div> */}
          {/* <div className="col"> */}
      
          {/* <p><strong>G. TOTAL:</strong> ₹{TotalGross}</p> */}
          {/* </div> */}
          {/* <div className="col"> */}
          {/* <p>{TotalGross}</p> */}
          {/* </div> */}
          <div className="d-flex justify-content-end fw-bold text-nowrap ">
         
         <p className="m-0"><strong>G. TOTAL:</strong> ₹{TotalGross}</p>
       
       </div>
        </div>
    
        {!saasId ==15 && TotalDiscountonInvoice > 0 && (
          <div className="d-flex justify-content-between">
            <p>Invoice Discount</p>

            <p className="text-nowrap">
              {Math.ceil((TotalDiscountonInvoice * 100) / totalamount)} %{" "}
              {TotalDiscountonInvoice}
            </p>
          </div>
        )}
        {!saasId ==15 && TotalDiscountonInvoice + totalitemDis > 0 && (
          <div className="d-flex justify-content-between">
            <p className="m-0">Total Discount</p>
            <p className="m-0">{Math.floor(TotalDiscountonInvoice + totalitemDis)}</p>
          </div>
        )}
        {/* {TotalDiscountonInvoice + totalitemDis > 0 && ( */}
          <div className="d-flex justify-content-between">
            <strong>Net Total</strong>
            <p><strong>{Math.round(totalamount - TotalDiscountonInvoice)}</strong></p>
          </div>
        {/* )} */}
        {/* <div className=" d-flex justify-content-between">
          <h5 className="fw-bold">Net Settlement: </h5>
          <h5 className="fw-bold">{TotalAmount}</h5>
        </div> */}
      </div>
      
      {/* <div className="container mb-1 " style={{ fontSize: "1.2rem" }}>
        <p style={{ fontSize: "1.2rem" }}>Settlement</p>
        {Tender &&
          Tender.map((item) => {
            return (
              <div className="row fw-bold">
                {item.tender_name == "Cr Notes" ? (
                  <div className="col text-nowrap">
                    <p>
                      {item.tender_name}({CreditId})
                    </p>
                  </div>
                ) : (
                  <div className="col text-nowrap">
                    <p>{item.tender_name}</p>
                  </div>
                )}
                <div className="col">
                  <p className="float-end">{item.amount}</p>
                </div>
              </div>
            );
          })}
      </div> */}
      {/* {Tender.length>1&&<div className=" d-flex justify-content-between">
            <h5 className='fw-bold'>Net Settlement: </h5>
            <h5 className='fw-bold'>{TotalAmount}</h5>
          </div>}  */}

      {/* <div className="fw-bold" style={customLineStyle}></div> */}

      {/* <div className="container" style={{ fontSize: "1.2rem" }}>
        <div className="col">
          {ChangeDue && (
            <div className="col p-2 fw-bold d-flex justify-content-between">
              <h5 className="fw-bold">Change Due: </h5>
              <h5 className="fw-bold">{ChangeDue} </h5>
            </div>
          )}
          {CutomerAmount && (
            <div className="col p-2 fw-bold d-flex justify-content-between">
              <h5 className="fw-bold">Customer Cash INR: </h5>
              <h5 className="fw-bold">{CutomerAmount} </h5>
            </div>
          )}
          {UsdAmount?.value && (
            <div className="col p-2 fw-bold d-flex justify-content-between">
              <h5 className="fw-bold">Customer Cash USD: </h5>
              <h5 className="fw-bold">{UsdAmount.value} </h5>
            </div>
          )}
        </div>
      </div> */}

      {/* <div className="container" style={{ fontSize: "1.1rem" }}>
        <div className="row">
          <p
            className="text-nowrap text-center"
            style={{
              fontSize: "0.9rem",
            }}
          >
            Date: {moment(Date.now()).format("DD/MM/YYYY,h:mm:ss a")} (
            {userName})
          </p>
          <div className="d-flex justify-content-center">
            <Barcode value={barcodeData} width={2} height={50} />
          </div>
        </div>
      </div> */}

      <div className="fw-bold" style={customLineStyle}></div>
      <div style={{display:"flex",justifyContent:"start"}}><strong>You Have Saved :-  {Math.floor(TotalDiscountonInvoice + totalitemDis)}</strong> </div>
      <div className="fw-bold m-0 p-0" style={customLineStyle}></div>
      <div style={{display:"flex",justifyContent:"start",fontSize:"0.7rem"}}><strong>Terms And Condition</strong> </div>
      <footer style={styles.footer}>
        {/* <div className="row">
          <p
            style={{ fontSize: "1rem" }}
            className="d-flex  justify-content-center fw-bold m-0"
          >
            {futterLine1}{" "}
          </p>
          <p
            style={{ fontSize: "1rem" }}
            className="d-flex  justify-content-center fw-bold m-0"
          >
            {" "}
            {futterLine2}
          </p>
          <p
            style={{ fontSize: "1rem" }}
            className="d-flex  justify-content-center fw-bold m-0"
          >
            {futterLine3}
          </p>
          <p
            style={{ fontSize: "1rem" }}
            className="d-flex  justify-content-center fw-bold m-0"
          >
            {" "}
            {futterLine4}{" "}
          </p>
          <p
            style={{ fontSize: "1rem" }}
            className="d-flex  justify-content-center fw-bold m-0"
          >
            {futterLine5}
          </p>
          <p
            style={{ fontSize: "1rem" }}
            className="d-flex  justify-content-center fw-bold m-0"
          >
            {" "}
            {futterLine6}
          </p>
          <p
            style={{ fontSize: "1rem" }}
            className="d-flex  justify-content-center fw-bold m-0"
          >
            {" "}
            {futterLine7}
          </p>
          <p
            style={{ fontSize: "1rem" }}
            className="d-flex  justify-content-center fw-bold m-0"
          >
            {" "}
            {futterLine8}
          </p>
          <p
            style={{ fontSize: "1rem" }}
            className="d-flex  justify-content-center fw-bold m-0"
          >
            {" "}
            {futterLine9}
          </p>
          <p
            style={{ fontSize: "1rem" }}
            className="d-flex  justify-content-center fw-bold m-0"
          >
            {" "}
            {futterLine10}
          </p>
        </div> */}

          <p style={{fontSize:"0.7rem"}}> <strong> {futterLine1}</strong> </p>
          <p style={{fontSize:"0.7rem"}}><strong>{futterLine2}</strong> </p>
          <p style={{fontSize:"0.7rem"}}><strong>{futterLine3}</strong> </p>
        </footer>

      {newCredit?.creditNote_id && (
        <div>
          <hr className="mb-5" style={{ color: "black" }} />
          <div
            className="container "
            style={{ fontSize: "1.25rem", marginTop: "5rem" }}
          >
            <p
              style={{ fontSize: "1.6rem" }}
              className="d-flex  justify-content-around fw-bold"
            >
              {storeName}
            </p>
            <p
              style={{ fontSize: "1.4rem" }}
              className="d-flex  justify-content-around fw-bold"
            >
              {haderLine1}
            </p>
            <p
              style={{ fontSize: "1.25rem" }}
              className="d-flex  justify-content-around fw-bold"
            >
              {haderLine2}
            </p>
            <p
              style={{ fontSize: "1.25rem" }}
              className="d-flex  justify-content-around fw-bold"
            >
              {haderLine3}
            </p>
            <p
              style={{ fontSize: "1.25rem" }}
              className="d-flex  justify-content-around fw-bold"
            >
              {haderLine4}
            </p>
            <p
              style={{ fontSize: "1.25rem" }}
              className="d-flex  justify-content-around fw-bold"
            >
              REISSUED CREDIT NOTE
            </p>
            <div className="row  fw-bold">
              <div className="col">
                <div className="d-flex justify-content-center">
                  <Barcode
                    value={newCredit.creditNote_id}
                    width={2}
                    height={50}
                  />
                </div>
              </div>

              <div className="fw-bold">********************************</div>
            </div>
            <div className="d-flex justify-content-between">
              <p>amount</p>
              <span>{newCredit.credit_note_amount}</span>
            </div>
          </div>
          <div className="container" style={{ fontSize: "1.2rem" }}>
            <div className="col">
              <div className="col p-2 fw-bold">
                <p className="fw-bold">Authorised by : </p>
                <p className="fw-bold">Accounts : </p>
              </div>
            </div>
          </div>

          <div className="container" style={{ fontSize: "1.2rem" }}>
            <div className="col">
              <div className="col p-2 fw-bold">
                <p className="fw-bold">Not valid after 180 Days</p>

                <p className="fw-bold">
                  valid upto: {moment(newCredit.EXP_DATE).format("DD/MM/YYYY")}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
     {
       newCoupon && (<div>
        <hr className="mb-5" style={{ color: "black" }} />
        <div>
          <div
            className="container "
            style={{ fontSize: "1.25rem", marginTop: "5rem" }}
          >
            {/* <p
              style={{ fontSize: "1.6rem" }}
              className="d-flex  justify-content-around fw-bold"
            >
              {storeName}
            </p>
            <p
              style={{ fontSize: "1.4rem" }}
              className="d-flex  justify-content-around fw-bold"
            >
              {haderLine1}
            </p>
            <p
              style={{ fontSize: "1.25rem" }}
              className="d-flex  justify-content-around fw-bold"
            >
              {haderLine2}
            </p>
            <p
              style={{ fontSize: "1.25rem" }}
              className="d-flex  justify-content-around fw-bold"
            >
              {haderLine3}
            </p>
            <p
              style={{ fontSize: "1.25rem" }}
              className="d-flex  justify-content-around fw-bold"
            >
              {haderLine4}
            </p> */}
            <p
              style={{ fontSize: "1.25rem" }}
              className="d-flex  justify-content-around fw-bold"
            >
              Congratulations You Got new Coupon !
            </p>
            <p
              style={{ fontSize: "1rem" }}
              className="d-flex  justify-content-around fw-bold text-center"
            >
              {newCoupon.description}
            </p>

            <div className="container" style={{ fontSize: "1.2rem" }}>
            <div className="col">
              <div className="col p-2 fw-bold">
                {/* <p className="fw-bold">Not valid after 180 Days</p> */}

                <p className="fw-bold text-center">
                  EXPIRES: {moment(newCoupon.expirationDate).format("DD/MM/YYYY")}
                </p>
              </div>
            </div>
          </div>


            <div className="row  fw-bold">
              <div className="col">
                <div className="d-flex justify-content-center">
                  <Barcode
                    value={newCoupon.couponCode}
                    width={2}
                    height={50}
                  />
                </div>
              </div>

              <div className="fw-bold text-center">********************************</div>
            </div>
            <div className="d-flex justify-content-between">
              <p>Discount Amount</p>
              <span>{newCoupon.discountAmount}</span>
            </div>
          </div>
          <div className="container" style={{ fontSize: "1.2rem" }}>
            <div className="col">
              <div className="col p-2 fw-bold">
                <p className="fw-bold">Authorised by : </p>
                <p className="fw-bold">Accounts : </p>
              </div>
            </div>
          </div>

          
        </div>
      </div>
      )}

    </div>
  );

  return (
    <PrintProvider>
      <Print>
        <div style={styles}>{receiptContent}</div>
      </Print>
    </PrintProvider>
  );
}

export default ReceiptModal;

// import React from 'react';
// import { PrintProvider, Print } from 'react-easy-print';

// function ReceiptModal() {
//   const styles = {
//     fontFamily: 'Verdana',
//     fontSize: 'medium',
//     fontWeight: 'bold',
//   };

//   const receiptContent = (
//     <div style={styles}>
//       <div className="fw-bold">
//         <p style={{ fontSize: '2rem' }}>V A S T R E T A I L</p>
//         <p>MAX, BABYSHOP, SHOEXPRESS,</p>
//         <p style={{ fontSize: '2rem' }}>SPLASH, GULAN MALL, ERBIL</p>
//         <p style={{ fontSize: '2rem' }}>SALES INVOICE</p>
//       </div>

//       <hr />

//       <div>
//         <p>Total: 9,500</p>
//         <p>(-) Discount 2%: -1,000</p>
//       </div>

//       <hr />

//       <div>
//         <p>Representative: Kim CEO</p>
//         <p>Business Registration Number: 000-00-00000</p>
//         <p>Representative Number: 0000-0000</p>
//         <p>Address: Some City, Some District, Some Dong, Some Ho</p>
//       </div>

//       <hr />

//       <br />

//       <div align="center">
//         <p style={{ fontWeight: 'bold' }}>Wifi: some-wifi / PW: 123123</p>
//       </div>
//     </div>
//   );

//   return (
//     <PrintProvider>
//       <Print
//       >
//        <div style={styles}>
//             {receiptContent}
//           </div>

//       </Print>
//     </PrintProvider>
//   );
// }

// export default ReceiptModal;
