import React, { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useDispatch } from "react-redux";
import { displayDataToCart } from "../Redux/Reducer";
import DataService from "../services/requestApi";
import Swal from 'sweetalert2';

export const AddNewItem = ({
  setAddItemModel,
  getFocus,
  addItemModel,
  displayData,
  itemId,
  setItemId
}) => {
  const { saasId, storeId } = JSON.parse(localStorage.getItem("User_data"));

  const dispatch = useDispatch();
  const [concepts, setConcepts] = useState();
  const [selectedOption, setSelectedOption] = useState("");

  const [addNewItem, setAddNewItem] = useState({
    colorList: null,
    item_id: Math.floor(1000000 + Math.random() * 9000000),
    item_name: "",
    UOM: null,
    description: "",
    special_description: null,
    price: "",
    item_price: "",
    brand: null,
    sub_price: null,
    actual_price: "",
    price_pcs: null,
    product_qty: "1",
    discount: 0,
    tax: 0,
    tax_percent: 0,
    status: "active",
    category: "IRAQ",
    saas_id: "1",
    store_id: "10001",
    promo_id: null,
    image_name: null,
    hsn_code: null,
    tax_rate: 0,
    tax_code: "0",
    barcode: "",
    supplier_name: null,
    opening_qty: null,
    received_qty: null,
    sold_qty: null,
    closing_qty: null,
    product_cost: null,
    product_price: null,
    product_av_cost: null,
    mrp: null,
    conc_id: null,
    dept: null,
    item_class: null,
    sub_class: null,
    item_code: "",
    newPrice: "",
    productQty: 1,
  });

  const focus = useRef();

  const handleNewItems = (e) => {
    const { name, value } = e.target;
    setAddNewItem({
      ...addNewItem,
      [name]: value,
    });
  };

  const handleCloseModal = () => {
    setAddItemModel(false);
    getFocus();
  };

  const handleAdd = async () => {
   // Validating mandatory fields
   if (!addNewItem.item_name || !addNewItem.price || !selectedOption || !addNewItem.item_code) {
    Swal.fire({
      icon: "warning",
      title: "All fields are required",
      text: "Please fill out all mandatory fields!",
    });
    return;
  }

  // Validate item code length (9 digits)
  if (addNewItem.item_code.length !== 9) {
    Swal.fire({
      icon: "error",
      title: "Invalid Item Code",
      text: "Please enter a 9-digit Item Code!",
    });
    return;
  }


    try {
      const data = {
        item_name: addNewItem.item_name,
        price: addNewItem.price,
        price_pcs: 5.99,
        discount: 0.0,
        special_description: "Special offer",
        actual_price: addNewItem.price,
        brand: "Sample Brand",
        tax: 0,
        status: "active",
        saas_id: saasId,
        store_id: storeId,
        hsn_code: "254456",
        promo_id: "promo789",
        category: "IRAQ",
        barcode: "1234567890",
        conc_id: selectedOption,
        product_qty: "1",
        dept: "Department",
        item_class: "Sample Class",
        sub_class: "Sample Subclass",
        item_code: addNewItem.item_code,
        class_code: null,
        dept_code: null,
        UOM: "",
        colorList: [
          // {
          //   product_size: 10,
          //   product_color: "Red",
          //   saas_id: "6",
          //   store_id: "60001",
          // },
          // {
          //   product_size: 12,
          //   product_color: "Blue",
          //   saas_id: "6",
          //   store_id: "60001",
          // },
        ],
      };

      // const updatedDisplayData = [...displayData];
      // updatedDisplayData.push(addNewItem);

      setAddNewItem({
        colorList: null,
        item_id: Math.floor(1000000 + Math.random() * 9000000),
        item_name: "",
        UOM: null,
        description: "",
        special_description: null,
        price: "",
        brand: null,
        sub_price: null,
        actual_price: "",
        price_pcs: null,
        product_qty: 1,
        discount: 0,
        tax: 0,
        tax_percent: 0,
        status: "active",
        category: "IRAQ",
        saas_id: "1",
        store_id: "10001",
        promo_id: null,
        image_name: null,
        hsn_code: null,
        tax_rate: 0,
        tax_code: "0",
        barcode: "",
        supplier_name: null,
        opening_qty: null,
        received_qty: null,
        sold_qty: null,
        closing_qty: null,
        product_cost: null,
        product_price: null,
        product_av_cost: null,
        mrp: null,
        conc_id: null,
        dept: null,
        item_class: null,
        sub_class: null,
        item_code: "",
        newPrice: "",
        productQty: 1,
      });
      const response = await DataService.AddNewItem(data);
      console.log(response.data.data);
      if(response.data.status){
        dispatch(displayDataToCart(response.data.data));
        setAddItemModel(false);
        setTimeout(() => {
          getFocus();
        }, 1000);
        setItemId("")
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const handleSelectConcept = async () => {
      try {
        const response = await DataService.GetConceptAll();
        const data = response.data.data;
        setConcepts(data);
      } catch (error) {
        console.error(error.message);
      }
    };

    if (addItemModel) {
      handleSelectConcept();
    }
  }, [addItemModel]);

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  useEffect(() => {
    if (addItemModel) {
      setTimeout(() => {
        focus.current.focus();
      }, 500);
    }
  }, [addItemModel]);

  return (
    <div>
      <Modal
        show={true}
        onHide={() => setAddItemModel(false)}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title>Add New Item</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="form-group">
            <label htmlFor="itemName">Item Name</label>
            <input
              onChange={handleNewItems}
              name="item_name"
              type="text"
              className="form-control"
              id="item_name"
              placeholder="Enter item name..."
              ref={focus}
            />
          </div>
      {/* New input for Item Code */}
      <div className="form-group">
            <label htmlFor="itemCode">Item Code</label>
            <input
              onChange={handleNewItems}
              name="item_code"
              type="number"
              className="form-control"
              id="item_code"
              placeholder="Enter 9-digit item code..."
              maxLength="9"
            />
          </div>
          <div style={{ marginTop: "0.5rem" }}>
            <label htmlFor="Select_concept">Select Concept</label>
            <Form.Select
              aria-label="Default select Example"
              onChange={handleOptionChange}
            >
              <option> Select Concept </option>
              {concepts &&
                concepts.map((concept) => (
                  <option key={concept.conc_id} value={concept.conc_id}>
                    {concept.conc_name}
                  </option>
                ))}
            </Form.Select>
          </div>

          <div className="form-group">
            <label htmlFor="price">Price per Unit</label>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">INR</span>
              </div>
              <input
                onChange={handleNewItems}
                name="price"
                type="number"
                className="form-control"
                id="price"
                placeholder="Price per unit..."
              />
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={handleAdd}>
            Add Item
          </Button>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
