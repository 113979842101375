import React, { useEffect, useMemo, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { IoArrowBack } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import Bahikhata from "./ShopandPurchaseTab/Bahikhata";
import DebitNote from "./ShopandPurchaseTab/DebitNote";
import Purchase from "./ShopandPurchaseTab/Purchase";
import DeliveryChalan from "./ShopandPurchaseTab/DeliveryChalan";
import { AddSupplier } from "./ShopandPurchaseTab/AddSupplier";
import DataService from './services/requestApi'
import OnlineMainpage from "./OnlineOrder/OnlineMainpage";
import { IoMdCart } from "react-icons/io";
import { handlonlineCart } from "./Redux/Reducer";
import { useDispatch } from "react-redux";
import { BiArrowBack } from "react-icons/bi";
import GetAllSuppliers from "./ShopandPurchaseTab/GetAllSuppliers";
const Shopandbuy = () => {
  const dispatch = useDispatch()
  const Opencart =()=>{
    dispatch(handlonlineCart(true))
  }
  const [taxes, settaxes] = useState()
  const navigate = useNavigate()
  const Gettaxes=async()=>{
    try {
      const response = await DataService.GetTaxes()
      if (response.data.status) {
        settaxes(response.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const savedCart = localStorage.getItem('OnlineCart');

const totalItems = useMemo(() => {
    if (savedCart) {
        const parsedCart = JSON.parse(savedCart);
        return parsedCart.length || 0; // Return the length or 0 if undefined
    }
    return 0; // Default to 0 if no cart is found
}, [savedCart]);
  return (
    <>
      <header className="bg-white shadow-sm fixed-top">
  <div className="container py-2 d-flex justify-content-between align-items-center">
    <h1
    style={{
      cursor:"pointer"
    }}
     onClick={()=>navigate("/")} className="h4 fw-bold text-dark mb-0"> <BiArrowBack/></h1>
  </div>
</header>

      <Tabs defaultActiveKey="OnlineOrder"
        id="uncontrolled-tab-example"
        className=" fw-bold"
        fill
        style={{
          marginTop:"56px",
          background: "#FFF",
          boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.25)",
        }}
        // onSelect={(key)=>{
        //   if(key=="Debit Note"){
        //     Gettaxes()
        //   }
        // }}
        >
        <Tab eventKey="OnlineOrder" title="Online Orders">
        <OnlineMainpage/>
        </Tab>
        <Tab eventKey="Expenses" title="Expense & Purchase">
        <Tabs
        defaultActiveKey="Purchase"
        id="uncontrolled-tab-example"
        className="mb-3  fw-bold"
        fill
        style={{
          background: "#FFF",
          boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.25)",
        }}
        onSelect={(key)=>{
          if(key=="Debit Note"){
            Gettaxes()
          }
        }}
      >
        <Tab eventKey="Purchase" title="Purchase">
          <Purchase />
        </Tab>
        <Tab eventKey="Debit Note" title="Debit Note">
          <DebitNote taxes={taxes}/>
        </Tab>
        <Tab eventKey="delhivery" title="Delhivery">
          <DeliveryChalan />
        </Tab>
        <Tab eventKey="BahiKhata" title="BahiKhata">
          <Bahikhata />
        </Tab>
        <Tab eventKey="Add Supplier" title="Add Suplier">
          {/* <AddSupplier /> */}
          <GetAllSuppliers/>
        </Tab>
      </Tabs>
        </Tab>
      </Tabs>
      
    </>
  );
};

export default Shopandbuy;
