import React, { useEffect, useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import OrderManagement from './GetNewOrders'
import DataService from '../services/requestApi'
import OrderDetailsModal from './OrderDetailsModal'
const OnlineMainpage = () => {
  const { storeId, saasId } = JSON.parse(localStorage.getItem("User_data"));
  const [orders, setOrders] = useState([]);
  const [active, setActive]= useState('PENDING')
  const [open, setOpen] = useState(false)
  const [orderData , setOrderData]= useState({})
  const [orderItem, setOrderItem] = useState([])
  const [Address, setAddress] = useState({})
  const [isLoading, setIsLoading]= useState(false)
  const onClose = ()=>{
    setOpen(false)
  }
  const GetOrders = async ()=>{
    try {
    const res = await DataService.GetAllOrders(saasId, storeId)
    if(res.data.status){
        setOrders(res.data.data)
    }
    } catch (error) {
        console.log(error)
    }
  }

  useEffect(() => {
    GetOrders()
  }, [])

  const getOrderDetail =async (orderId)=>{
    setIsLoading(true)
    try {
      const res = await DataService.GetOrderDetail(saasId, storeId, orderId)
      if(res.data.status){
        setOrderData(res.data.data)
        const address = await DataService.GetAddress(saasId, storeId, res.data.data.address_id)
        if(address.data.status){
          setAddress(address.data.data)
        }
        const orderItem = await DataService.GetOrderItemData(saasId, storeId, orderId)
        if(orderItem.data.status){
          setOrderItem(orderItem.data.data || [])
        }
        if(res.data.status && address.data.status && orderItem.data.status){
          setIsLoading(false)
          setOpen(true)
        }
      }
    } catch (error) {
      setIsLoading(false)
      console.log(error)
    }finally{
      setIsLoading(false)
    }
  }
  

   
  return (
    <>
    <Tabs
    defaultActiveKey="PENDING"
    id="controlled-tab-example"
    className="mb-3 fw-bold"
    role="tablist"
    aria-label="controlled example"
    fill
        style={{
          // marginTop:"56px",
          background: "#FFF",
          boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.25)",
        }}
      onSelect={(key)=>{
        setActive(key)
      }}
    
    >
      <Tab eventKey="PENDING" title="Pending" >
      <OrderManagement loading={isLoading} getOrderDetail ={getOrderDetail} setOpen={setOpen} GetOrders={GetOrders} orders={orders} setOrders={setOrders} status={active}/>
      </Tab>
      {/* <Tab eventKey="Processing" title="Processing" >
      <OrderManagement orders={orders} setOrders={setOrders} status={active}/>

      </Tab> */}
      <Tab eventKey="delivered" title="Delivered" >
      <OrderManagement GetOrders={GetOrders}  orders={orders} setOrders={setOrders} status={active}/>
      </Tab>
      <Tab eventKey="cancel" title="Canceled" >
      <OrderManagement GetOrders={GetOrders}  orders={orders} setOrders={setOrders} status={active}/>
      </Tab>

    </Tabs>
    <OrderDetailsModal GetOrders={GetOrders} open={open} onClose={onClose} orderData={orderData} orderItems={orderItem} customerAddress={Address}/>
    </>
  )
}

export default OnlineMainpage