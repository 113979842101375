import React, { useState, useEffect, useCallback } from "react";
import { styled } from "@mui/material/styles";

import DataService from "../../.././services/requestApi";
import Swal from "sweetalert2";
import { BASEURL } from "../../../services/http-common";
import {
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  Typography,
  Box,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
} from "@mui/material";
import axios from "axios";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
function StoreMasterModal(props) {
  const { saasId, storeId, userId } = JSON.parse(
    localStorage.getItem("User_data")
  );
  const [countries, setCountries] = useState([]);
  // const [userId, setUserId] = useState([]);
  const { show, onHide ,GetStoreMaster} = props;
  const [StoreMasterData, setStoreMasterData] = useState({
    user_id: 123456,
    store_id: "store123",
    saas_id: " ",
    store_name: "",
    city: "",
    state: " ",
    country: " ",
    address: " ",
    taxable: true,
    gst_code: " ",
    tnc: " ",
    hsn_code: " ",
    store_type: " ",
    exclusive_tax: 0,
    inclusive_tax: 0,
    store_logo: "logo_url",
    bank_account: "account_number",
    bank_ifsc: "ifsc_code",
    bank_branch: "branch_name",
    payment_qrcode: " ",
    receipt_format: 1,
    email: " ",
    phone_no: " ",
    check_inventory: "inventory_status",
  });
  // console.log(props.show);
  //country and user id fetching:--------
  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const response = await axios.get("https://restcountries.com/v3.1/all");
        const data = response.data;
        const countryOptions = data.map((country) => ({
          label: country.name.common,
          value: country.name.common,
        }));
        setCountries(countryOptions);
      } catch (error) {
        console.error(error.message);
      }
    };

    // const fetchUserId = async () => {
    //   try {
    //     const response = await fetch(
    //       `${BASEURL.ENDPOINT_URL}/register/get-all-id/${saasId}/${storeId}`
    //     );
    //     const { data } = await response.json();
    //     console.log("userid", data);
    //     const userIdData = data.map((user) => ({
    //       label: user.user_id,
    //       value: user.user_id,
    //     }));
    //     // setUserId(userIdData);
    //     console.log(userId);
    //   } catch (error) {
    //     console.error(error.message);
    //   }
    // };
    if (show) {
      // fetchUserId();
      fetchCountry();
    }
  }, [show]);

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setStoreMasterData((prevState) => ({
  //     ...prevState,
  //     [name]: value,
  //   }));
  // };

  // const handleSubmitStoreMasterData = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const response = await DataService.StoreMaster(StoreMasterData);
  //     if (response.status === 200) {
  //       Swal.fire({
  //         title: "Details Added Successfully in Store Master",
  //         icon: "success",
  //         confirmButtonText: "Done",
  //         timer: 3000,
  //       });
  //       props.setModalShow(false);
  //     }
  //   } catch (error) {
  //     console.log(error);

  //     Swal.fire({
  //       title: "Something Not Working ",
  //       icon: "error",
  //       confirmButtonColor: "red",
  //       confirmButtonText: "Retry",
  //     });
  //   }
  // };

  //stylings
  // const inputStyle = {
  //   boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.1)",
  //   borderRadius: "8px",
  //   overflow: "hidden",
  //   padding: "12px",
  //   border: "1px solid #ddd",
  //   transition: "box-shadow 0.3s ease-in-out",
  // };

  // const ButtonStyle = {
  //   width: "50%",
  //   borderRadius: "8px",
  //   background: "#0067CE",
  //   color: "#fff",
  //   padding: "12px",
  //   cursor: "pointer",
  //   border: "none",
  //   transition: "background 0.3s ease-in-out",
  // };

  // inputStyle["&:hover"] = {
  //   boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2), 0 8px 16px rgba(0, 0, 0, 0.2)",
  // };

  // ButtonStyle[":hover"] = {
  //   background: "#00509E",
  // };

  // inputStyle["&:focus"] = {
  //   boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2), 0 8px 16px rgba(0, 0, 0, 0.2)",
  //   outline: "none",
  // };

  // ButtonStyle[":focus"] = {
  //   outline: "none",
  // };

  // inputStyle["&:active"] = {
  //   boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1)",
  // };

  // ButtonStyle[":active"] = {
  //   background: "#003366",
  // };

  //-------------------------
  const [formData, setFormData] = useState({
    userId: userId,
    storeId: "",
    saasId: "",
    storeName: "",
    empId: "",
    city: "",
    state: "",
    country: "",
    address: "",
    taxable: true,
    gstCode: "",
    tnc: "",
    hsnCode: "",
    storeType: "",
    exclusiveTax: 0,
    deliveryCharges: 0,
    minimumOrderValue: 0,
    inclusiveTax: 0,
    bankAccount: "",
    bankIfsc: "",
    bankBranch: "",
    eMail: "",
    phoneNo: "",
    checkInventory: "Y",
    storeAddress1: "",
    storeAddress2: "",
    haderLine1: "",
    haderLine2: "",
    haderLine3: "",
    haderLine4: "",
    futterLine1: "",
    futterLine2: "",
    futterLine3: "",
    futterLine4: "",
    futterLine5: "",
    futterLine6: "",
    futterLine7: "",
    futterLine8: "",
    futterLine9: "",
    futterLine10: "",
    key_id: "KEY12345",
    key_secret: "SECRET67890",
    countrycode: "EST",
    start: "09:00:00",
    end: "18:00:00",
    scan_bill: false,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [SaasIds, setSaasIds] = useState([])
  const [page, setPage] = useState(1)
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prev) => ({ ...prev, [name]: checked }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${BASEURL.ENDPOINT_URL}/store-master/create-store1`,
        formData
      );
      if (response.data.status) {
        Swal.fire({
          title: "Success",
          text: "Store created successfully",
          icon: "success",
        });
        UploadImage(formData.storeId)

      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.data.message,
        });
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const UploadImage = (id) => {
    try {
      const formData = new FormData();
      formData.append("file", image);

      const res = DataService.UploadImage(id,formData);
    } catch (error) {
      console.log(error);
    }
  }; 

  const fetchSaasIds = async () => {
    try {
      const response = await DataService.GetSaasIds(page);
      const { data } = response.data;
      const saasIdOptions = data.map((saas) => ({
        label: saas.saas_id,
        value: saas.saas_id,
      }));
      setSaasIds(saasIdOptions);
    } catch (error) {
      console.error("Error fetching Saas IDs:", error);
    }
  };

  useEffect(() => {
    if(show){
      fetchSaasIds();
    }
  }, [show]);

  return (
    <Modal open={show} onClose={onHide} aria-labelledby="modal-title" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Box
        sx={{
          maxWidth: 800,
          width: '100%',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 3,
          borderRadius: 2,
          overflowY: 'auto',
          maxHeight: '90vh',
        }}
      >
        <Card sx={{ maxWidth: 800, margin: "auto" }}>
          <CardHeader title="Create Store" />
          <CardContent>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField fullWidth label="Store Name" name="storeName" value={formData.storeName} onChange={handleInputChange} required />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField fullWidth label="Store ID" name="storeId" value={formData.storeId} onChange={handleInputChange} required />
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>Select Saas Id</InputLabel>
                    <Select
                      value={formData.saasId}
                      onChange={(e) => setFormData((prev) => ({ ...prev, saasId: e.target.value }))}
                      label="Select Saas Id"
                    >
                      {SaasIds.map((saasId) => (
                        <MenuItem key={saasId.value} value={saasId.value}>
                          {saasId.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {/* <TextField fullWidth label="saasId ID" name="saasId" value={formData.saasId} onChange={handleInputChange} required /> */}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField fullWidth label="City" name="city" value={formData.city} onChange={handleInputChange} required />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField fullWidth label="State" name="state" value={formData.state} onChange={handleInputChange} required />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>Select Country</InputLabel>
                    <Select
                      value={formData.country}
                      onChange={(e) => setFormData((prev) => ({ ...prev, country: e.target.value }))}
                      label="Select Country"
                    >
                      {countries.map((country) => (
                        <MenuItem key={country.value} value={country.value}>
                          {country.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth label="Address" name="address" value={formData.address} onChange={handleInputChange} multiline rows={2} required />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField fullWidth label="Email" name="eMail" type="email" value={formData.eMail} onChange={handleInputChange} required />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField fullWidth label="Phone Number" name="phoneNo" value={formData.phoneNo} onChange={handleInputChange} required />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField fullWidth label="Bank Account" name="bankAccount" value={formData.bankAccount} onChange={handleInputChange} required />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField fullWidth label="Bank IFSC" name="bankIfsc" value={formData.bankIfsc} onChange={handleInputChange} required />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField fullWidth label="Bank Branch" name="bankBranch" value={formData.bankBranch} onChange={handleInputChange} required />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField fullWidth label="Concept Id" placeholder="Ex: 1,2,11" name="hsnCode" value={formData.hsnCode} onChange={handleInputChange} required />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>Store Type</InputLabel>
                    <Select
                      value={formData.storeType}
                      onChange={(e) => setFormData((prev) => ({ ...prev, storeType: e.target.value }))}
                      label="Store Type"
                    >
                      <MenuItem value="GROCERY">GROCERY</MenuItem>
                      <MenuItem value="MEDICAL">MEDICAL</MenuItem>
                      <MenuItem value="VEGETABLE">VEGETABLE</MenuItem>
                      <MenuItem value="SWEET">SWEET</MenuItem>
                      <MenuItem value="FASHION">FASHION</MenuItem>
                      <MenuItem value="SALOON">SALOON</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel control={<Checkbox checked={formData.taxable} onChange={handleCheckboxChange} name="taxable" />} label="Taxable" />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel control={<Checkbox checked={formData.scan_bill} onChange={handleCheckboxChange} name="scan_bill" />} label="Scan Bill" />
                </Grid>
                <Grid item xs={6}>
                  <Button component="label" role={undefined} variant="contained" tabIndex={-1} startIcon={<CloudUploadIcon />}>
                    Upload Store Logo
                    <VisuallyHiddenInput type="file" onChange={(event) => setImage(event.target.files[0])} multiple />
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Header Lines
                  </Typography>
                  <Grid container spacing={2}>
                    {[1, 2, 3, 4].map((num) => (
                      <Grid item xs={12} sm={6} key={`header-${num}`}>
                        <TextField fullWidth label={`Header Line ${num}`} name={`haderLine${num}`} value={formData[`haderLine${num}`]} onChange={handleInputChange} />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Footer Lines
                  </Typography>
                  <Grid container spacing={2}>
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((num) => (
                      <Grid item xs={12} sm={6} key={`footer-${num}`}>
                        <TextField fullWidth label={`Footer Line ${num}`} name={`futterLine${num}`} value={formData[`futterLine${num}`]} onChange={handleInputChange} />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </CardContent>
          <CardActions>
            <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isLoading}>
              {isLoading ? "Creating Store..." : "Create Store"}
            </Button>
            <Button variant="contained" color="primary" onClick={onHide} disabled={isLoading}>
              Close
            </Button>
          </CardActions>
        </Card>
        </Box>
        </Modal>
  );
}

export default StoreMasterModal;
