import React, { useEffect, useState } from 'react';
import { Button, IconButton } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { Box } from '@mui/system';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2';
import DataService from '../services/requestApi'
import AddSupplier from './AddSupplier';
import { BASEURL } from '../services/http-common';
import axios from 'axios';
import UpdateSupplier from './UpdateSupplier';
const GetAllSuppliers = () => {
    const { storeId, saasId } = JSON.parse(localStorage.getItem('Store_data'));
    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [partyOptions, setPartyOptions] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [selectedSupplier, setSelectedSupplier] = useState(null);
    const [MenufactureModalOpen, setMenufactureModalOpen] = useState(false);
    const GetSupplier = async(pagenumber)=>{
        try {
            const response = await DataService.GetAllSupplier(saasId,storeId,pagenumber)
            if(response.data.status){
                setPartyOptions(response.data.data)
                setTotalRows(response.data.totalRows)
            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        GetSupplier(page);
      }, [page]);
      const handlePageChange = (newPage) => {
        setPage(newPage);
      };

      const handleDelete = async (id) => {
        try {
          const result = await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          });
    
          if (result.isConfirmed) {
            const apiUrl = `${BASEURL.ENDPOINT_URL}/supplier/deleteSuplire/${id}`;
            const response = await axios.delete(apiUrl);
            Swal.fire('Deleted!', 'Supplier has been deleted.', 'success');
            GetSupplier(1); // Refresh the list after deletion
          }
        } catch (error) {
          Swal.fire('Error', 'Failed to delete  Supplier', 'error');
        }
      };

      const handleEditClick = (supplier) => {
        setSelectedSupplier(supplier);
        setUpdateModalOpen(true);
      };

    const columns = [
        {
          name: 'ID',
          selector: (row) => row.supplier_id,
          sortable: true,
        },
        {
          name: 'Name',
          selector: (row) => row.party_name,
          sortable: true,
        },
    
        {
          name: 'Email',
          selector: (row) => `${row.email}`,
          sortable: true,
        },
        {
          name: 'Contact Info',
          selector: (row) => `${row.phone_number}`,
          sortable: false,
        },
        {
          name: 'Actions',
          selector: (row) => row.actions,
          center: true,
          cell: (row) => (
            <>
              <IconButton aria-label="edit"  
              onClick={() => handleEditClick(row)}
              >
                <Edit style={{ color: 'green' }} />
              </IconButton>
              <IconButton aria-label="delete" 
              onClick={() => handleDelete(row.supplier_id)}
              >
                <Delete style={{ color: '#C81D20' }} />
              </IconButton>
            </>
          ),
        },
      ];
  return (
    <>
    <div className="p-4">
  <Box
    className="mb-2"
    p={2}
    borderRadius={2}
    border="1px solid #e0e0e0"
    boxShadow="0 2px 10px rgba(0,0,0,0.1)"
    width="100%"
    height="fit-content"
    bgcolor="white"
  >
    <div className="d-flex justify-content-between align-items-center">
      <h1 className="fs-3">Create Supplier Directory</h1>
      <Button
        className="fw-bold"
        style={{ background: 'rgb(14, 41, 84)' }}
        variant="contained"
        color="primary"
        onClick={() => setMenufactureModalOpen(true)}
      >
        Create
      </Button>
    </div>
  </Box>
  <div className="bg-white p-4 shadow rounded">
    <h2 className="fs-5 mb-3 fw-bold">Supplier Directory</h2>
    <div style={{ height: '300px', overflow: 'auto' }}>
      <DataTable
        columns={columns}
        data={partyOptions}
        pagination
        responsive
        striped
        highlightOnHover
        pointerOnHover
        noHeader
        paginationServer
        paginationTotalRows={totalRows}
        onChangePage={handlePageChange}
      />
    </div>
  </div>
</div>
<AddSupplier getPartyOptions={GetSupplier}  open={MenufactureModalOpen} onHide={() => setMenufactureModalOpen(false)} />
<UpdateSupplier
        isOpen={updateModalOpen}
        toggle={() => setUpdateModalOpen(false)}
        supplierData={selectedSupplier}
        refreshList={() => GetSupplier(page)}
      />
    </>
  )
}

export default GetAllSuppliers