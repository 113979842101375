import React, { useState } from 'react'
import Container from 'react-bootstrap/Container';
import { MdDelete,MdEdit, MdPlaylistAdd } from "react-icons/md";
import DataTable from "react-data-table-component";
import DataService from '../../services/requestApi'
import SubClassModal from './MangeAllModal/SubClassModal';
import AddSubClass from './MangeAllModal/AddSubClass';
import Swal from 'sweetalert2';
import { Button } from '@mui/material';

function SubClassMaster({classData,concept,Department,loading,SubClass,GetSubReport,handlePageChange,setSize , size ,count}) {
    //Update SubClass Functionlity
    const [addSubClass, setaddSubClass] = useState(false)

    const [OpenUpdate, setOpenUpdate] = useState(false)
    const [SelectedRow, setSelectedRow] = useState("")
   
    const handleRowsPerPageChange = (newRowsPerPage) => {
      setSize(newRowsPerPage);
    };


  const deleteConcept = async (sub_class_code) => {
    try {
      const response = await DataService.deleteSubClass(sub_class_code); // Assuming you have this function in your DataService
      console.log("Delete Concept", response.data);
      if (response.data.status) {
        Swal.fire({
          title:"SubClass Delete Successfully",
          icon:"success",
          timer:2000
      })
      GetSubReport()
      }
      // Optionally, you can update the state or perform any other actions after successful deletion.
    } catch (error) {
      console.error("Error deleting concept", error);
    }
  };

    const style={
        boxShadow:"4px 4px 10px 0px rgba(0, 0, 0, 0.25)",
        borderRadius:"4px",
        background:"white",
        color:"black",
        border:"0px"
      }
      const columns = [
        {
          name: "Department Code",
          center: true,
          selector: (row) => row.dept_code,
        },
        {
          name: "Department Name",
          center: true,
          selector: (row) => row.dept_name,
        },
        {
          name: "Concept Code",
          center: true,
          selector: (row) => row.con_code,
        },
        {
          name: "Concept Name",
          center: true,
          selector: (row) => row.con_name,
        },

        {
          name: "Class Code",
          center: true,
          selector: (row) => row.class_code,
        },
        {
          name: "Class Name",
          center: true,
          selector: (row) => row.class_name,
        },
        {
          name: "SubClass Code",
          // center: true,
          selector: (row) => row.sub_class_code,
          cell: (row) => {
            return (
              <>
                <div >{row.sub_class_code}</div>
              </>
            );
          },
        },
      
        {
          name: "Sub ClassName ",
          center: true,
          selector: (row) => row.sub_class_name,
        },
      
  
        {
          name: "Action",
          center: true,
          selector: (row) => {
           
    
            return (
              <>
                <div className="d-flex">
                <div style={{cursor:'pointer'}}>
                    <MdPlaylistAdd
                      size={22}
                      color="green"
                      className="mouse-pointer"
                      onClick={() => {
                        setaddSubClass(true)
                        setSelectedRow(row)
                      }}
                    />
                  </div>
    
                  <div style={{cursor:'pointer'}}>
                    <MdDelete
                      size={22}
                      color="red"
                      className="mouse-pointer"
                      onClick={() => deleteConcept(row.sub_class_code)}
                    />
                  </div>
    
                  <div style={{cursor:'pointer'}}>
                    <MdEdit
                      size={22}
                      color="var(--primary1)"
                      className="mouse-pointer"
                      onClick={() => {
                        setOpenUpdate(true)
                        setSelectedRow(row)
                      }}
                    />
                  </div>
                </div>
    
              </>
            );
          },
        },
      ];
  return (
    <div>  <Container>

       <AddSubClass concept={concept} Department={Department} classData={classData} open={addSubClass} GetSubReport={GetSubReport}  setaddSubClass={setaddSubClass} />

       <SubClassModal open={OpenUpdate} GetSubReport={GetSubReport} setOpenUpdate={setOpenUpdate} setSelectedRow={setSelectedRow} row ={SelectedRow}/>
       <div className="d-flex justify-content-between align-items-center px-3">
                   <h1 className="fs-3">Create Sub Class</h1>
                   <div className="d-flex gap-3"> 
             
                   <Button
                     className="fw-bold text-white"
                     style={{ background: 'rgb(14, 41, 84)' }}
                     variant="contained"
                     color="primary"
                     onClick={() =>setaddSubClass(true)}
                   >
                     Create Sub Class
                   </Button>
                     </div>
                 </div>
    <DataTable
        columns={columns}
        responsive={true}
        fixedHeader={true}
        // fixedHeaderScrollHeight="300px"

        data={SubClass}
        progressPending={loading}
        pagination
        paginationServer
        paginationRowsPerPageOptions={[5, 10, 15, 20]}
        paginationTotalRows={count}
        // paginationTotalRows={item_master_list ? item_master_list.totalCount : 1}
        onChangeRowsPerPage={handleRowsPerPageChange}
        onChangePage={handlePageChange}
        rowsPerPage={size}
      />
  </Container></div>
  )
}

export default SubClassMaster