import React, { useMemo, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Menu,
  MenuItem,
  Typography
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DataService from '../services/requestApi'
import { useEffect } from 'react';
import Swal from 'sweetalert2';

export default function OrderManagement({loading,setOpen,GetOrders,orders,setOrders,status,getOrderDetail}) {
  
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const { storeId, saasId } = JSON.parse(localStorage.getItem("User_data"));
  
  //filter data by 
  
  const filteredOrders = useMemo(() => {
    return orders.filter(order => order.status === status);
  }, [orders, status]);

  const handleClick = (event, orderId) => {
    setAnchorEl(event.currentTarget);
    setSelectedOrderId(orderId);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedOrderId(null);
  };

  const handleStatusUpdate =async (newStatus) => {
    if (selectedOrderId !== null) {
        const res = await DataService.UpdateOrderByid(storeId, saasId, selectedOrderId, newStatus)
        if(res.data.status){
            Swal.fire({
                title: 'Success',
                text: 'Order status updated successfully',
                icon: 'success',
            })
            GetOrders()
        }
    //   setOrders((prevOrders) =>
    //     prevOrders.map((order) =>
    //       order.order_id === selectedOrderId ? { ...order, status: newStatus } : order
    //     )
    //   );
      // In a real application, you would also send this update to your backend
      console.log(`Updated order ${selectedOrderId} status to ${newStatus}`);
    }
    handleClose();
  };

  return (
    <div style={{ padding: '20px' }}>
      <Typography variant="h4" gutterBottom>
        Order Management
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Order ID</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Customer</TableCell>
              <TableCell>Mobile</TableCell>
              <TableCell>Value</TableCell>
              <TableCell>Payment</TableCell>
              <TableCell>Status</TableCell>
             {status=="PENDING"&& <TableCell>Action</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredOrders&&filteredOrders.map((order) => (
              <TableRow key={order.order_id}>
                <TableCell>{order.order_id}</TableCell>
                <TableCell>{order.order_date}</TableCell>
                <TableCell>{order.customer_name}</TableCell>
                <TableCell>{order.mobile_number}</TableCell>
                <TableCell>₹{order.order_value}</TableCell>
                <TableCell>{order.payment_type}</TableCell>
                <TableCell>{order.status}</TableCell>
                <TableCell>
                  {order.status =="PENDING"?<Button
                    disabled={loading}
                    variant="outlined"
                    onClick={()=>getOrderDetail(order.order_id)}
                  >
                   {loading?"Loading ..." :"Process Order"}
                  </Button>
                  :""
                  // <Button
                  //   variant="outlined"
                  //   endIcon={<KeyboardArrowDownIcon />}
                  //   onClick={(event) => handleClick(event, order.order_id)}
                  // >
                  //   Update Status
                  // </Button>
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleStatusUpdate('PENDING')}>Pending</MenuItem>
        <MenuItem onClick={() => handleStatusUpdate('Delivered')}>Delivered</MenuItem>
        <MenuItem onClick={() => handleStatusUpdate('cancel')}>Cancel</MenuItem>
        {/* <MenuItem onClick={() => handleStatusUpdate('CANCELLED')}>Cancelled</MenuItem> */}
      </Menu>
    </div>
  );
}
