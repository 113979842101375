import React, { useState } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { MdDelete,MdEdit, MdPlaylistAdd } from "react-icons/md";
import DataTable from "react-data-table-component";
import DataService from '../../services/requestApi'

import UpdateConeptModal from './MangeAllModal/UpdateConeptModal';
import AddConcept from './MangeAllModal/AddConcept';
import Swal from 'sweetalert2';
import { Button } from '@mui/material';
function ConceptMaster({data,GetConcept}) {

  console.log("Concept data", data)
 const deleteConcept = async (conc_id) => {
  try {
    const response = await DataService.deleteConcept(conc_id); // Assuming you have this function in your DataService
    console.log("Delete Concept", response.data);
    if(response.data.status){
      GetConcept()
      Swal.fire({
        title:'Concept Delete Successfully',
        icon:"success",
        timer:2000
      })
    }
    // Optionally, you can update the state or perform any other actions after successful deletion.
  } catch (error) {
    console.error("Error deleting concept", error);
  }
};


    const style={
        boxShadow:"4px 4px 10px 0px rgba(0, 0, 0, 0.25)",
        borderRadius:"4px",
        background:"white",
        color:"black",
        border:"0px"
      }

      //Update Concept Functionlity
      const [OpenUpdate, setOpenUpdate] = useState(false)
      const [SelectedRow, setSelectedRow] = useState("")
      const [OpenAddCon, setOpenAddCon] = useState(false)




      const columns = [
        // {
        //   name: "Conc id",
        //   // center: true,
        //   selector: (row) => row.conc_id,
        //   cell: (row) => {
        //     return (
        //       <>
        //         <div >{row.conc_id}</div>
        //       </>
        //     );
        //   },
        // },
        // {
        //   name: "Image",
        //   center: true,
        //   selector: (row) => row.image_name,
        //   cell: (row) => {
        //     return (
        //       <>
        //       {/* <Image src={`${BASE_Url}/item/get-image/${row && row.item_id}`} alt="" /> */}
        //         {/* <div style={{ fontWeight: "bolder" }}>{row.item_name}</div> */}
        //       </>
        //     );
        //   },
        // },
        {
          name: "Concept Code ",
          center: true,
          selector: (row) => row.conc_code,
        },
        {
          name: "Concept Name",
          center: true,
          selector: (row) => row.conc_name,
        },
        {
          name: "Saas id",
          center: true,
          selector: (row) => row.saas_id,
        },
        // {
        //     name: 'Discount',
        //     center: true,
        //     selector: row => row.discount,
        // },
        {
          name: "Store id",
          center: true,
          selector: (row) => row.store_id,
        },
        
        // {
        //   name: "Tax",
        //   center: true,
        //   selector: (row) => row.tax,
        // },
        // {
        //     name: 'Tax Code',
        //     center: true,
        //     selector: row => row.tax_code,
        // },
        // {
        //     name: 'Tax Percent',
        //     center: true,
        //     selector: row => row.tax_percent,
        // },
        // {
        //     name: 'Tax Rate',
        //     center: true,
        //     selector: row => row.tax_rate,
        // },
        {
          name: "Action",
          center: true,
          selector: (row) => {
            // const [addUpdateItemModalIsOpen, setAddUpdateItemModalIsOpen] =
            //   useState(false);
            // const handleDelete = async () => {
            //   const response = await fetch(
            //     `${host}item/inactive-item/${row.item_id}/${saasId}`,
            //     {
            //       method: "PUT",
            //     }
            //   );
            //   const jsonData = await response.json();
            //   if (jsonData) {
            //     if (jsonData.status === true) {
            //       toast.success(jsonData.message);
            //       setFlag(!flag);
            //       return;
            //     }
            //     /* toast.error(jsonData.message);
            //       setFlag(!flag); */
            //   } /*  else {
            //       toast.error("Something went wrong server side");
            //     } */
            // }; /* catch (err) {
            //     toast.error(err.message);
            //   } */
    
    
            return (
              <>
                <div className="d-flex">
                <div style={{cursor:'pointer'}}>
                    <MdPlaylistAdd
                      size={22}
                      color="green"
                      className="mouse-pointer"
                      onClick={() => setOpenAddCon(true)}
                    />
                  </div>
    
                  <div style={{cursor:'pointer'}}>
                    <MdDelete
                      size={22}
                      color="red"
                      className="mouse-pointer"
                      onClick={() => deleteConcept(row.conc_id)}
                    />
                  </div>
    
                  <div style={{cursor:'pointer'}}>
                    <MdEdit
                      size={22}
                      color="var(--primary1)"
                      className="mouse-pointer"
                      onClick={() => {
                        setOpenUpdate(true)
                        setSelectedRow(row)
                      }}
                    />
                  </div>
                </div>
    
                {/* <AddItem
                  addUpdateItemModalIsOpen={addUpdateItemModalIsOpen}
                  setAddUpdateItemModalIsOpen={setAddUpdateItemModalIsOpen}
                  row={row}
                  setFlag={setFlag}
                  flag={flag}
                /> */}
              </>
            );
          },
        },
      ];
  return (
    <div>  <Container>
      <AddConcept open={OpenAddCon} GetConcept={GetConcept} setOpenAddCon={setOpenAddCon}/>
      <UpdateConeptModal open={OpenUpdate} GetConcept={GetConcept} setOpenUpdate={setOpenUpdate} row ={SelectedRow}/>
      <div className="d-flex justify-content-between align-items-center px-3">
            <h1 className="fs-3">Create Concept</h1>
            <div className="d-flex gap-3"> 
      
            <Button
              className="fw-bold text-white"
              style={{ background: 'rgb(14, 41, 84)' }}
              variant="contained"
              color="primary"
              onClick={() =>setOpenAddCon(true)}
            >
              CREATE Concept
            </Button>
              </div>
          </div>
  
    <DataTable
        columns={columns}
        responsive={true}
        fixedHeader={true}
        // fixedHeaderScrollHeight="300px"

        data={data}
        // progressPending={loading}
        pagination
        paginationServer
        // paginationTotalRows={item_master_list ? item_master_list.totalCount : 1}
        // onChangeRowsPerPage={10}
        // onChangePage={handlePageChange}
      />
  </Container></div>
  )
}

export default ConceptMaster