import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Button, TextField, Checkbox, FormControlLabel } from "@mui/material";
import axios from "axios";
import Swal from "sweetalert2";
import { BASEURL } from "../services/http-common";

const UpdateSupplier = ({ isOpen, toggle, supplierData, refreshList }) => {

    
  const [formData, setFormData] = useState('');

  useEffect(() => {
    setFormData(supplierData)
  }, [supplierData])
  
console.log("first",supplierData)
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prev) => ({ ...prev, [name]: checked }));
  };

  const handleSubmit = async () => {
    try {
      const apiUrl = `${BASEURL.ENDPOINT_URL}/supplier/update/${formData.supplier_id}`;
      const response = await axios.put(apiUrl, formData);
      Swal.fire("Success", "Supplier updated successfully", "success");
      refreshList();
      toggle(); // Close the modal
    } catch (error) {
      Swal.fire("Error", "Failed to update supplier", "error");
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>Update Supplier</ModalHeader>
      <ModalBody>
        <TextField
          label="Party Name"
          name="party_name"
          value={formData?.party_name}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="GSTIN"
          name="gstin"
          value={formData?.gstin}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Phone Number"
          name="phone_number"
          value={formData?.phone_number}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Email"
          name="email"
          value={formData?.email}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Billing Address"
          name="billing_address"
          value={formData?.billing_address}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Opening Balance"
          name="opening_balance"
          type="number"
          value={formData?.opening_balance}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Credit Limit Amount"
          name="creditLimitAmount"
          type="number"
          value={formData?.creditLimitAmount}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        {/* <FormControlLabel
          control={
            <Checkbox
              checked={formData?.credit_limit_flag}
              onChange={handleCheckboxChange}
              name="credit_limit_flag"
            />
          }
          label="Credit Limit Flag"
        /> */}
      </ModalBody>
      <ModalFooter>
        <Button style={{marginRight:"10px"}} className="mr-2" variant="contained" color="primary" onClick={handleSubmit}>
          Update
        </Button>
        <Button className="mr-2" variant="outlined" color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default UpdateSupplier;
