import { Autocomplete, Button, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { BASEURL } from '../../services/http-common';
import axios from 'axios';
import DataService from '../../services/requestApi'
import Swal from 'sweetalert2';
const UpdateInvantory = () => {
    const [itemDetail, setItemDetail] = useState({
        item_name: "",
        quantity: "",
      });
      const { storeId, saasId } = JSON.parse(localStorage.getItem("User_data"));
    const [allinventory, setAllInventoryData] = useState([])
    const [search , setSearch] = useState("")
    const [loading, setLoading] = useState(false)
      const getAllInventoryList = (search) => {
        setLoading(true)
        axios
          .get(
            `${BASEURL.ENDPOINT_URL}/search/get-result/${storeId}/${saasId}/${search}`
          )
          .then((res) => {
            if (res.status === 200) {
              res.data.data.forEach((el) => {
                el["value"] = el.item_id;
                el["label"] = el.item_name;
              });
              setAllInventoryData(res.data.data);
              setLoading(false)
            }
          })
          .catch((err) => {
            setLoading(false)
            console.log(err)});
      };

    const UpdateInventory = async () => {
      if (!itemDetail.item_name) {
    Swal.fire({
      title: "Error",
      text: "Please select an item",
      icon: "error",
      confirmButtonText: "OK"
    })
        return;
      }
      if (!itemDetail.quantity || isNaN(itemDetail.quantity) || itemDetail.quantity <= 0) {
        Swal.fire({
            title: "Error",
            text: "Please enter a valid quantity",
            icon: "error",
            confirmButtonText: "OK"
          })
        return;
      }
      try {
        const res = await DataService.UpdateInventory(itemDetail.item_name, itemDetail.quantity);
        if (res.data.status) {
            Swal.fire({
                title: "Success",
                text: "Inventory updated successfully",
                icon: "success",
                confirmButtonText: "OK"
              })
        } else {
            Swal.fire({
                title: "Error",
                text: "Failed to update inventory",
                icon: "error",
                confirmButtonText: "OK"
              })
        }
      } catch (error) {
        console.log(error);
        Swal.fire({
            title: "Error",
            text: "An error occurred while updating inventory",
            icon: "error",
            confirmButtonText: "OK"
          })
      }
    }


      const handelitemDetailChange = (e) => {
        const { name, value } = e.target;
        setItemDetail((prevState) => ({
         ...prevState,
          [name]: value
        }));
      };
       
      useEffect(() => {
        if(search){
          getAllInventoryList(search);
        }
      }, [search]);
  return (
    <div
  className="d-flex flex-column"
  style={{
    alignItems: "center", // Align items vertically in the center
    gap: "10px", // Add spacing between elements
    flexWrap: "wrap", // Wrap items if they overflow
  }}
>
  <Autocomplete
    options={allinventory}
    getOptionLabel={(option) => option.label || ""} // Determine label display
    value={allinventory?.find((opt) => opt.item_id === itemDetail.item_name) || null}
    // value={itemDetail?.item_name}
    onChange={(event, newValue) => {
      console.log(newValue);
      handelitemDetailChange({
        target: { name: "item_name", value: newValue?.item_id || "" },
      });
    }}
    onInputChange={(event, newInputValue) => {
      setSearch(newInputValue); // Update input value for API call
    }}
    renderInput={(params) => (
      <TextField
        {...params}
        name="item_name"
        placeholder="Search Item"
        className="form-control"
        style={{
          width: "500px",
        }}
        aria-describedby="nameHelp"
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <>
              {loading ? <span>Loading...</span> : null}
              {params.InputProps.endAdornment}
            </>
          ),
        }}
      />
    )}
    isOptionEqualToValue={(option, value) => option.label === value.label}
  />
  <TextField
    style={{
      width: "500px",
    }}
    name="quantity"
    placeholder="Receiving Quantity"
    className="form-control"
    onChange={handelitemDetailChange}
    value={itemDetail.quantity}
  />
  <Button
    variant="contained"
    color="secondary"
    style={{ marginTop: "10px" }}
    onClick={UpdateInventory}
  >
    Update
  </Button>
</div>

  )
}

export default UpdateInvantory