import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Col } from "react-bootstrap";
import { BsArrowLeft } from "react-icons/bs";
import { TextField } from "@mui/material";
import DataService from "../../../services/requestApi";
import Swal from "sweetalert2";

const DepartmentUpdateModal = ({
  concept,
  open,
  setOpenUpdate,
  row,
  GetDeptMaster,
}) => {
  const { saasId, storeId } = JSON.parse(localStorage.getItem("User_data"));
  const [deptName, setDeptName] = useState("");
  const [concId, setConcId] = useState("");
  const [conc_name, setconc_name] = useState("");
  const [dept_code, setdept_code] = useState("");

  const data = {
    saas_id: saasId,
    store_id: storeId,
    conc_id: concId,
    dept_name: deptName,
    dept_code: dept_code,
    conc_name: conc_name,
  };

  useEffect(() => {
    if (row) {
      setDeptName(row.dept_name);
      setConcId(row.conc_id);
      setdept_code(row.dept_code);
      setconc_name(row.conc_name); // Assuming conc_name is the name of the department's associated concept
    }
    console.log(row);
  }, [row]);
  // API call for update department
  const UpdateDepartment = async () => {
    try {
      if (!row || !row.dept_id) {
        console.error("Invalid row object or missing dept_id");
        return;
      }
      if (concId && deptName && dept_code) {
        const response = await DataService.UpdateDepart(row.dept_id, data);
        if (response.data.status) {
          GetDeptMaster();
          setOpenUpdate(false);
        }
      } else {
        Swal.fire({
          title: "Please fill All field",
          icon: "error",
        });
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const handleChange = (e) => {
    if (e.target.name === "dept_name") {
      setDeptName(e.target.value);
    } else if (e.target.name === "conc_id") {
      setConcId(e.target.value);
    } else if (e.target.name === "dept_code") {
      setdept_code(e.target.value);
    } else {
      setconc_name(e.target.value);
    }
  };

  return (
    <div>
      <Modal isOpen={open} toggle={() => setOpenUpdate(!open)}>
        <ModalHeader>
          <BsArrowLeft
            onClick={() => setOpenUpdate(!open)}
            className="mouse-pointer"
          />
          Update Department *
        </ModalHeader>
        <ModalBody>
          <div className="row d-flex justify-content-center">
            <div className="">
              <form className="form-box" encType="Content-Type">
                <div
                  className="d-flex flex-col"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <Col md={12}></Col>
                  <TextField
                    size="small"
                    type="text"
                    className="form-control my-2"
                    name="dept_name"
                    value={deptName}
                    onChange={handleChange}
                    label="Department Name"
                    multiline
                    required
                    rows={1}
                  />
                  <TextField
                    select
                    size="small"
                    className="form-control my-2"
                    value={concId|| ""}
                    name="conc_name"
                    onChange={(e) => {
                      const selectedOption = e.target.value;
                      // Find the selected option from the array of options
                      const selected = concept.find(
                        (el) => el.conc_code == selectedOption
                      );
                      console.log("Selected Concept", selected, selectedOption);
                      if (selected) {
                        console.log(
                          "Selected Concept",
                          selected,
                          selectedOption,
                          concept
                        );
                        setConcId(selected.conc_code);
                        setconc_name(selected.conc_name);
                      }
                    }}
                    required
                    SelectProps={{
                      native: true,
                    }}
                  >
                    <option value="">Select Concept</option>
                    {concept &&
                      concept?.map((el) => (
                        <option key={el.conc_code} value={el.conc_code}>
                          {el.conc_name}
                        </option>
                      ))}
                  </TextField>
                  <TextField
                    id="outlined-number"
                    type="number"
                    className="form-control my-2"
                    value={dept_code}
                    name="dept_code"
                    onChange={handleChange}
                    label="Department id"
                  />
                  {/* <TextField
                    size="small"
                    id="outlined-number"
                    type="number"
                    className="form-control my-2"
                    value={concId}
                    name="conc_id"
                    onChange={handleChange}
                    label="Concept id"
                    multiline
                    required
                    rows={1}
                  /> */}
                </div>
                <div className="mt-2">
                  <button
                    className=""
                    type="button"
                    style={{
                      backgroundColor: "rgb(46, 69, 175)",
                      outline: "none",
                      border: "none",
                      fontSize: "20px",
                      padding: "10px 20px",
                      borderRadius: "10px",
                      color: "#fff",
                    }}
                    onClick={UpdateDepartment}
                  >
                    Update
                  </button>
                  <span
                    onClick={() => setOpenUpdate(!open)}
                    className="btn btn-primary mb-2"
                    style={{
                      backgroundColor: "grey",
                      outline: "none",
                      border: "none",
                      marginLeft: "20px",
                      fontSize: "20px",
                      padding: "10px 20px",
                      borderRadius: "10px",
                      color: "#fff",
                    }}
                  >
                    Close
                  </span>
                </div>
              </form>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>{/* <h1>FOOTER</h1> */}</ModalFooter>
      </Modal>
    </div>
  );
};

export default DepartmentUpdateModal;
