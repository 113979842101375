import React, { useState, useEffect } from "react";
import { Card, CardBody, Col, Form, FormGroup, Input, Row } from "reactstrap";
import { styled } from "@mui/material/styles";
// import Select from "react-select";
// import Modal from "react-bootstrap/Modal";
import DataService from "../../.././services/requestApi";
import Swal from "sweetalert2";
import {
  TextField,
  Switch,
  Button,
  Grid,
  Typography,
  Paper,
  FormControlLabel,
  Box,
  Modal
} from '@mui/material';
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
function UpdateStoremasterModal(props) {
  const { saasId, storeId } = JSON.parse(localStorage.getItem("User_data"));
  const { selectedRow ,selectedRowData,onHide,show} = props;
  const [image, setImage] = useState(null);
  const initialStoreData = {
    storeId: selectedRowData?.store_id,
    saasId: selectedRowData?.saas_id,
    storeName: selectedRowData?.store_name,
    empId: null,
    city: selectedRowData?.city,
    state: selectedRowData?.state,
    country: selectedRowData?.country,
    address: selectedRowData?.address,
    taxable: selectedRowData?.taxable,
    gstCode: selectedRowData?.gst_code,
    tnc: selectedRowData?.tnc,
    hsnCode: selectedRowData?.hsn_code,
    storeType: selectedRowData?.store_type,
    exclusiveTax: selectedRowData?.exclusive_tax,
    deliveryCharges: selectedRowData?.delivery_Charges,
    minimumOrderValue: 0,
    inclusiveTax: selectedRowData?.inclusive_tax,
    bankAccount: selectedRowData?.bank_account,
    bankIfsc: selectedRowData?.bank_ifsc,
    bankBranch: selectedRowData?.bank_branch,
    eMail: selectedRowData?.e_mail,
    phoneNo: selectedRowData?.phone_no,  
    checkInventory: null,
    storeAddress1: null,
    storeAddress2: null,
    haderLine1: selectedRowData?.headerLine1,
    haderLine2: selectedRowData?.headerLine2,
    haderLine3: selectedRowData?.headerLine3,
    haderLine4: selectedRowData?.headerLine4,
    futterLine1: selectedRowData?.futterLine1,
    futterLine2: selectedRowData?.futterLine2,
    futterLine3: selectedRowData?.futterLine3,
    futterLine4: selectedRowData?.futterLine4,
    futterLine5: selectedRowData?.futterLine5,
    futterLine6: selectedRowData?.futterLine6,
    futterLine7: selectedRowData?.futterLine7,
    futterLine8: selectedRowData?.futterLine8,
    futterLine9: selectedRowData?.futterLine9,
    futterLine10: selectedRowData?.futterLine10,
    key_id: null,
    key_secret: null,
    countrycode: "USA",
    start: null,
    end: null,
    scan_bill: false
  };
  const [storeData, setStoreData] = useState(initialStoreData);
  useEffect(() => {
    setStoreData(initialStoreData);
  }, [selectedRowData]);
  
  const handleSubmitStoreMasterData = async (e) => {
    e.preventDefault();
    try {
      const response = await DataService.UpdateStoreMaster(
        storeData
      );
      if (response.data.status) {
        Swal.fire({
          title: "Details Added Successfully in Store Master",
          icon: "success",
          confirmButtonText: "Done",
          timer: 3000,
        });
        if(image){
          UploadImage(storeData.storeId)
        }
        onHide();
      }
    } catch (error) {
      console.log(error);

      Swal.fire({
        title: "Something Not Working ",
        icon: "error",
        confirmButtonColor: "red",
        confirmButtonText: "Retry",
      });
    }
  };

   
  const UploadImage = (id) => {
      try {
        const formData = new FormData();
        formData.append("file", image);
  
        const res = DataService.UploadImage(id,formData);
      } catch (error) {
        console.log(error);
      }
    };

  //-------------------------
  
  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    setStoreData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value
    }));
  };
  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   console.log('Updated Store Data:', storeData);
  //   // Here you would typically send the data to your API
  // };
  return (
    <Modal open={show} onClose={onHide}>
      {/* <Modal.Header className="container" closeButton>
        <Modal.Title>Store Master</Modal.Title>
      </Modal.Header> */}
      {/* <Modal.Body> */}
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxHeight:'calc(100vh - 0px)',
          overflowY:'auto',
          width: "90%",
          maxWidth: 900,
          bgcolor: "background.paper",
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
        }}
      >
      <Paper elevation={3} sx={{ p: 3, maxWidth: 800, margin: 'auto', mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        Update Store Information
      </Typography>
      <form onSubmit={handleSubmitStoreMasterData}>
        <Grid container spacing={3}>
          {/* /* Basic Information */  }
          <Grid item xs={12}>
            <Typography variant="h6">Basic Information</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Store ID"
              name="storeId"
              value={storeData.storeId}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="SAAS ID"
              name="saasId"
              value={storeData.saasId}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Store Name"
              name="storeName"
              value={storeData.storeName}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              select
              label="Store Type"
              name="storeType"
              value={storeData.storeType}
              onChange={handleInputChange}
              SelectProps={{
                native: true,
              }}
            >
              <option value="GROCERY">GROCERY</option>
              <option value="MEDICAL">MEDICAL</option>
              <option value="VEGETABLE">VEGETABLE</option>
              <option value="SWEET">SWEET</option>
              <option value="FASHION">FASHION</option>
              <option value="SALOON">SALOON</option>
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">Location Information</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="City"
              name="city"
              value={storeData.city}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="State"
              name="state"
              value={storeData.state}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Country"
              name="country"
              value={storeData.country}
              onChange={handleInputChange}
            />
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Country Code"
              name="countrycode"
              value={storeData.countrycode}
              onChange={handleInputChange}
            />
          </Grid> */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Address"
              name="address"
              value={storeData.address}
              onChange={handleInputChange}
              multiline
              rows={2}
            />
          </Grid>
          

          {/* Tax and Financial Information */}
          <Grid item xs={12}>
            <Typography variant="h6">Tax and Financial Information</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={
                <Switch
                  checked={storeData.taxable}
                  onChange={handleInputChange}
                  name="taxable"
                />
              }
              label="Taxable"
            />
          </Grid>
          <Grid item xs={6}>
                  <Button component="label" role={undefined} variant="contained" tabIndex={-1} startIcon={<CloudUploadIcon />}>
                    Upload Store Logo
                    <VisuallyHiddenInput type="file" onChange={(event) => setImage(event.target.files[0])} multiple />
                  </Button>
                </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="GST Code"
              name="gstCode"
              value={storeData.gstCode}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="HSN Code"
              name="hsnCode"
              value={storeData.hsnCode}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Store Type"
              name="storeType"
              value={storeData.storeType}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Exclusive Tax"
              name="exclusiveTax"
              type="number"
              value={storeData.exclusiveTax}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Inclusive Tax"
              name="inclusiveTax"
              type="number"
              value={storeData.inclusiveTax}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Delivery Charges"
              name="deliveryCharges"
              type="number"
              value={storeData.deliveryCharges}
              onChange={handleInputChange}
            />
          </Grid>
         

          {/* Bank Information */}
          {/* <Grid item xs={12}>
            <Typography variant="h6">Bank Information</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Bank Account"
              name="bankAccount"
              value={storeData.bankAccount}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Bank IFSC"
              name="bankIfsc"
              value={storeData.bankIfsc}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Bank Branch"
              name="bankBranch"
              value={storeData.bankBranch}
              onChange={handleInputChange}
            />
          </Grid> */}

          {/* Contact Information */}
          <Grid item xs={12}>
            <Typography variant="h6">Contact Information</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Email"
              name="eMail"
              type="email"
              value={storeData.eMail}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Phone Number"
              name="phoneNo"
              value={storeData.phoneNo}
              onChange={handleInputChange}
            />
          </Grid>

          {/* Store Settings */}
          <Grid item xs={12}>
            <Typography variant="h6">Store Settings</Typography>
          </Grid>
          
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={
                <Switch
                  checked={storeData.scan_bill}
                  onChange={handleInputChange}
                  name="scan_bill"
                />
              }
              label="Scan Bill"
            />
          </Grid>
         

          {/* Header and Footer Information */}
          <Grid item xs={12}>
            <Typography variant="h6">Header Information</Typography>
          </Grid>
          {[1, 2, 3, 4].map((num) => (
            <Grid item xs={12} sm={6} key={`header${num}`}>
              <TextField
                fullWidth
                label={`Header Line ${num}`}
                name={`haderLine${num}`}
                value={storeData[`haderLine${num}`]}
                onChange={handleInputChange}
              />
            </Grid>
          ))}

          <Grid item xs={12}>
            <Typography variant="h6">Footer Information</Typography>
          </Grid>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((num) => (
            <Grid item xs={12} sm={6} key={`footer${num}`}>
              <TextField
                fullWidth
                label={`Footer Line ${num}`}
                name={`futterLine${num}`}
                value={storeData[`futterLine${num}`]}
                onChange={handleInputChange}
              />
            </Grid>
          ))}

          {/* API Keys */}
          
          {/* Terms and Conditions */}
          {/* <Grid item xs={12}>
            <Typography variant="h6">Terms and Conditions</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Terms and Conditions"
              name="tnc"
              value={storeData.tnc}
              onChange={handleInputChange}
              multiline
              rows={4}
            />
          </Grid> */}

          {/* Submit Button */}
          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-end" className="gap-3">
              <Button type="submit" variant="contained" color="primary">
                Update Store
              </Button>
              <Button type="Button" onClick={onHide} variant="contained" color="primary">
                Close
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Paper>
    </Box>
      {/* </Modal.Body> */}
    </Modal>
  );
}

export default UpdateStoremasterModal;
