import React, { useEffect, useState } from 'react';
import { Modal, Tabs, Tab, Box, Typography, TextField, Button } from '@mui/material';
import DataService from '../../../services/requestApi';
import Swal from 'sweetalert2';
const AddCategoryModal = ({ open, handleClose ,fetchCategories}) => {
    const [tabIndex, setTabIndex] = useState(0);
    const { saasId, storeId } = JSON.parse(localStorage.getItem("User_data"))
    const [categorylist,setCategoryList]=useState([])
    const [categoryName, setCategoryName] = useState({
        master_category_name: "",
        saas_id: saasId,
        store_id: storeId
    });
    const [subcatgoryform, setSubCategoryForm] = useState({
        saas_id: saasId,
        store_id: storeId,
        category: "",
        master_category_id: "1"
    });
    const [categoryImage, setCategoryImage] = useState(null);
    const [subcategoryImage, setSubCategoryImage] = useState(null);
    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };
    //on change for sub category form 
    const handleSubCategoryChange = (e) => {
        setSubCategoryForm({ ...subcatgoryform, [e.target.name]: e.target.value })
    }
    
    const handleCreateMasterCategory =async () => {
        // Add logic to create master category
        try {
            const formData = new FormData();
            
            const response = await DataService.AddCategoryMaster(categoryName);
            if (response.data.status) {
                
                if (categoryImage && response.data.data.master_category_id) {
                    formData.append('file', categoryImage);
                    const imageResponse = await DataService.UpdateimageMasterCategory(response.data.data.master_category_id, formData);
                    if (imageResponse.data.status) {
                        handleClose();
                    }
                }
                fetchCategories()
                Swal.fire({
                    title: "Master Category Added Successfully",
                    icon: "success",
                    timer: 2000
                });
                handleClose();
            }
            console.log(response);
        } catch (error) {
            console.error(error);
            Swal.fire({
                title: "Error",
                text: error.message,
                icon: "error",
                timer: 2000
            });

        }
    };
   

     const GetMasterCategory = async()=>{
        try {
            const response = await DataService.GetAllCategoryMaster(saasId,storeId);
            console.log("master category",response.data.data)
            setCategoryList(response.data.data)
        }
        catch (error) {
            console.error(error);
        }
    }

 useEffect(() => {
    if(open){
        GetMasterCategory();
    }
    }, [open]);


    const hnadelAddSubCategory = async () => {
        // Add logic to create sub category
        try {
            const formData = new FormData();
            const response = await DataService.CreatSubCategory(subcatgoryform);
            if (response.data.status) {
                if (subcategoryImage && response.data.data.category_id) {
                    formData.append('file', subcategoryImage);
                    const imageResponse = await DataService.AddSubcategoryImage(response.data.data.category_id, formData);
                    if (imageResponse.data.status) {
                        handleClose();
                    }
                }
                Swal.fire({
                    title: "Sub Category Added Successfully",
                    icon: "success",
                    timer: 2000
                });
                handleClose();
            }
            console.log(response);
        } catch (error) {
            console.error(error);
            Swal.fire({
                title: "Error",
                text: error.message,
                icon: "error",
                timer: 2000
            });

        }
    }


    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={{ width: 500, bgcolor: 'background.paper', margin: 'auto', mt: 5, p: 3 }}>
                <Tabs value={tabIndex} onChange={handleTabChange} centered>
                    <Tab label="Create Master Category" />
                    <Tab label="Create Sub Category" />
                </Tabs>
                {tabIndex === 0 && (
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="h6">Create Master Category</Typography>
                        <TextField
                        name="master_category_name"
                        value={categoryName.master_category_name}
                        onChange={(e) => setCategoryName({ ...categoryName, [e.target.name]: e.target.value })}
                        fullWidth label="Category Name" margin="normal" />
                        <input
                            type="file"
                            accept="image/*"
                            onChange={(e) => setCategoryImage(e.target.files[0])}
                            style={{ marginTop: '16px', marginBottom: '16px' }}
                        />
                        <Button onClick={handleCreateMasterCategory} variant="contained" color="primary">Create</Button>
                    </Box>
                )}
                {tabIndex === 1 && (
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="h6">Create Sub Category</Typography>
                        <TextField onChange={handleSubCategoryChange} name="category" value={subcatgoryform.category} fullWidth label="Sub Category Name" margin="normal" />
                       
                        <TextField
                            select
                            fullWidth
                            label="Parent Category"
                            margin="normal"
                            SelectProps={{
                                native: true,
                            }}
                            name='master_category_id'
                            onChange={handleSubCategoryChange}
                        >
                            <option value="">Select Parent Category</option>
                            {categorylist.map((category) => (
                                <option key={category.masterCategoryId} value={category.masterCategoryId}>
                                    {category.masterCategoryName}
                                </option>
                            ))}
                        </TextField>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={(e) => setSubCategoryImage(e.target.files[0])}
                            style={{ marginTop: '16px', marginBottom: '16px' }}
                        />

                        <Button onClick={hnadelAddSubCategory} variant="contained" color="primary">Create</Button>
                    </Box>
                )}
            </Box>
        </Modal>
    );
};

export default AddCategoryModal;