import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Button, Col, FormControl, InputGroup, Row } from "react-bootstrap";
import AddItemModal from "./MangeAllModal/AddItemModal";
import { BASEURL } from "../../services/http-common";
import { MdDelete, MdEdit, MdPlaylistAdd, MdPrint } from "react-icons/md";
import { FaUpload } from "react-icons/fa";
// import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import GetItemBarcodeModal from "../../UiComponents/GetItemBarcodeModal";
import { FaSearch } from "react-icons/fa";
import axios from "axios";
import { FormControlLabel, Switch } from "@mui/material";
import DataService from '../../services/requestApi'
function ItemMaster({
  loading,
  handlePageChange,
  data,
  count,
  GetItemReport,
  setData,
}) {
  const navigate = useNavigate();
  const style = {
    boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.25)",
    borderRadius: "4px",
    background: "white",
    color: "black",
    border: "0px",
  };
  const [modalShow, setModalShow] = useState(false);
  const [addUpdateItemModalIsOpen, setAddUpdateItemModalIsOpen] =
    useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [flag, setFlag] = useState(false);
  const handleDelete = async (row) => {
    const { saasId } = JSON.parse(localStorage.getItem("User_data")) || {};

    try {
      const response = await fetch(
        `${BASEURL.ENDPOINT_URL}/item/inactive-item/${row.item_id}/${saasId}`,
        {
          method: "PUT",
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const jsonData = await response.json();

      if (jsonData && jsonData.status === true) {
        GetItemReport();
        setFlag(!flag);
      }
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };

  const [invoiceNumber, setInvoiceNumber] = useState("");
  const { saasId, storeId } = JSON.parse(localStorage.getItem("User_data"));

  const handleSubmit = (event) => {
    event.preventDefault();
    if (invoiceNumber) {
      axios
        .get(
          `${BASEURL.ENDPOINT_URL}/search/get-result/${storeId}/${saasId}/${invoiceNumber}`
        )
        .then((res) => {
          setData(res.data.data);
        })
        .catch((err) => {
          console.log("ERR CUSTOMER INVOICE NO.", err);
        });
    } else {
      setData(data);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInvoiceNumber(value);

    if (!value || value.length === 0) {
      GetItemReport();
    }
  };

  const [selectedRows, setSelectedRows] = useState([]);

  const handleSelectionChange = (row) => {
    const isSelected = selectedRows.includes(row);
    const newSelectedRows = isSelected
      ? selectedRows.filter((id) => id !== row)
      : [...selectedRows, row];

    setSelectedRows(newSelectedRows);

    if (!isSelected) {
      console.log("Selected Row Data:", row);
    }
  };
  const [selectAll, setSelectAll] = useState(false);

  // useEffect(() => {
  //  console.log("selectedRows",selectedRows)
  // }, [selectedRows])
  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      // If selecting all, set all rows to selected
      setSelectedRows(data);
    } else {
      // If unselecting all, clear selected rows
      setSelectedRows([]);
    }
  };

  const handleChange = async (row,event) => {
    const { saasId } = JSON.parse(localStorage.getItem("User_data")) || {};
    const newStatus = event.target.checked ? "Active" : "Inactive";

    try {
      const response = await DataService.InActiveStatus(row.item_id, saasId,newStatus)

      if (!response.data.status) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }


      if (response.data.status) {
        GetItemReport();
        setFlag(!flag);
      }
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };

  const columns = [
    {
      name: "Select Item",
      center: true,
      cell: (row) => (
        <input
          type="checkbox"
          checked={selectAll || selectedRows.includes(row)}
          onChange={() => handleSelectionChange(row)}
        />
      ),
      // width: "100px",
    },
    {
      name: "Item Code",
      selector: (row) => row.item_code,
      cell: (row) => <div>{row.item_code}</div>,
      // width: "100px",
    },
    {
      name: "Item Name",
      selector: (row) => row.item_name,
      cell: (row) => <div>{row.item_name}</div>,
      // width: "100px",
    },
    {
      name: "Concept Code",
      center: true,
      selector: (row) => row.conc_id,
      // width: "100px",
    },
    {
      name: "Image",
      center: true,
      selector: (row) => row.item_id,
      cell: (row) => {
        return (
          <>
            <img
              src={`${BASEURL.ENDPOINT_URL}/item/get-image/${row.item_id}`}
              alt=""
              style={{ width: "50px", height: "50px" }}
            />
          </>
        );
      },
      // width: "100px",
    },
    {
      name: "Price",
      center: true,
      selector: (row) => row.price,
      width: "100px",
    },
    {
      name: "Actual Price",
      center: true,
      selector: (row) => row.actual_price,
      width: "100px",
    },
    {
      name: "Status",
      center: true,
      selector: (row) => (<>
       <div style={{ cursor: "pointer" }} className="d-flex justify-content-center ">
              <FormControlLabel
                control={
                  <Switch
                    checked={row.status =="Active"}
                    onChange={(e)=>{handleChange(row,e)}}
                    color="primary"
                  />
                }
                // label={isChecked ? 'On' : 'Off'}
              />
            </div>
      </>
      )
    },
    {
      name: "Action",
      center: true,
      selector: (row) => (
        <>
          <div className="d-flex">
            <div style={{ cursor: "pointer" }}>
              <MdPlaylistAdd
                size={22}
                color="green"
                className="mouse-pointer"
                onClick={() => navigate("/add-item")}
              />
            </div>
            <div style={{ cursor: "pointer" }} className="me-2">
              <MdDelete
                size={22}
                color="red"
                className="mouse-pointer"
                onClick={() => handleDelete(row)}
              />
            </div>
            <div style={{ cursor: "pointer" }} className="me-2">
              <FaUpload
                size={22}
                color="blue"
                className="mouse-pointer"
                onClick={() => {
                  setSelectedRow(row);
                  setUploadModalShow(true);
                }}
              />
            </div>
            <div style={{ cursor: "pointer" }}>
              <MdEdit
                size={22}
                color="var(--primary1)"
                className="mouse-pointer"
                onClick={() => {
                  setSelectedRow(row);
                  setAddUpdateItemModalIsOpen(true);
                }}
              />
            </div>
            <div style={{ cursor: "pointer" }} className="me-2 item-center">
              <MdPrint
                size={22}
                color="green"
                className="mouse-pointer"
                onClick={() => setModalShow(true)}
              />
            </div>
          </div>
        </>
      ),
      width: "200px",
    },
  ];

  const [uploadModalShow, setUploadModalShow] = useState(false);
  const handleImageUpload = (image) => {
    const data = new FormData();
    data.append("file", image);
    // data.append("item_id", selectedRow.item_id);
    axios
      .post(
        `${BASEURL.ENDPOINT_URL}/item/save-image2/${selectedRow.item_id}`,
        data
      )
      .then((res) => {
        console.log("Image uploaded", res);
        setUploadModalShow(false);
        // GetItemReport();
      })
      .catch((err) => {
        console.log("ERR UPLOAD IMAGE", err);
      });
  };

  //create UploadImageModal component
  const UploadImageModal = ({ show, onHide, onUpload }) => {
    const [image, setImage] = useState(null);

    const handleImageChange = (e) => {
      setImage(e.target.files[0]);
    };

    const handleUpload = () => {
      onUpload(image);
    };

    return (
      <div
        className={`modal fade ${show ? "show d-block" : ""}`}
        style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Upload Image</h5>
              <button
                type="button"
                className="btn-close"
                onClick={onHide}
              ></button>
            </div>
            <div className="modal-body">
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={onHide}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleUpload}
              >
                Upload
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center px-3">
        <h1 className="fs-3">Create Item</h1>
        <div className="d-flex gap-3">
          <Button
            className="fw-bold text-white"
            style={{ background: "rgb(14, 41, 84)" }}
            variant="contained"
            color="primary"
            onClick={() => navigate("/add-item")}
          >
            CREATE ITEM
          </Button>
        </div>
      </div>
      <div className="mx-5">
        {/* Add other buttons as needed */}
        <div style={{ position: "relative", top: "40px" }}>
          <label>
            <input
              type="checkbox"
              checked={selectAll}
              onChange={handleSelectAll}
            />
            Select All
          </label>
        </div>

        <div
          className="container "
          style={{ position: "relative", left: "80px" }}
        >
          <Row className="m-1">
            <Col xs={12} sm={8}>
              <InputGroup className="">
                <FormControl
                  placeholder="Search Item Name"
                  aria-label="Search"
                  aria-describedby="basic-addon2"
                  value={invoiceNumber}
                  onChange={handleInputChange}
                />
                <Button
                  onClick={handleSubmit}
                  style={{
                    width: "77px",
                    height: "40px",
                    flexShrink: 0,
                    background: "#565ADD",
                  }}
                >
                  <FaSearch />{" "}
                </Button>
              </InputGroup>
            </Col>
          </Row>
        </div>
        <hr />
        <DataTable
          columns={columns}
          responsive={true}
          fixedHeader={true}
          data={data}
          pagination
          paginationServer
          paginationTotalRows={count}
          progressPending={loading}
          onChangePage={(page) => handlePageChange(page)}
        />

        <AddItemModal
          GetItemReport={GetItemReport}
          addUpdateItemModalIsOpen={addUpdateItemModalIsOpen}
          setAddUpdateItemModalIsOpen={setAddUpdateItemModalIsOpen}
          row={selectedRow}
          setFlag={setFlag}
          flag={flag}
        />
      </div>
      <UploadImageModal
        show={uploadModalShow}
        onHide={() => setUploadModalShow(false)}
        onUpload={handleImageUpload}
      />
      <GetItemBarcodeModal
        item={selectedRows}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

export default ItemMaster;
