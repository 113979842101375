import React, { useState, useEffect } from 'react';
import DataTable from "react-data-table-component";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { Container } from 'react-bootstrap';
import AddCategoryModal from './AddCategorymodal';
import DataService from '../../../services/requestApi';
const GetAllCategory = ({tab}) => {
    const [categories, setCategories] = useState([]);
    const [open, setOpen] = useState(false);
    const [currentCategory, setCurrentCategory] = useState({ id: '', name: '' });
    const { saasId, storeId } = JSON.parse(localStorage.getItem("User_data"))
    const [Switch,  setTab] = useState('master')

    const fetchCategories = async () => {
        // Replace with your API call
        const response = await DataService.GetAllCategoryMaster(saasId, storeId);
        setCategories(response.data.data);
    };
    const GetCategory = async()=>{
          try {
              const response = await DataService.GetSubCategory(saasId,storeId);
              console.log("Category",response.data.data)
              setCategories(response.data.data)
          } catch (error) {
              console.error(error);
          } 
        }
    useEffect(() => {
      // Fetch categories from API or database
      if(Switch=="master"){

        fetchCategories();
      }else{
        GetCategory()
      }
  }, [Switch]);

    const handleAddEdit = () => {
        if (currentCategory.id) {
            // Update category
            setCategories(categories.map(cat => (cat.id === currentCategory.id ? currentCategory : cat)));
        } else {
            // Add new category
            setCategories([...categories, { ...currentCategory, id: categories.length + 1 }]);
        }
        setOpen(false);
        setCurrentCategory({ id: '', name: '' });
    };

    const handleEditClick = (category) => {
        setCurrentCategory(category);
        setOpen(true);
    };

    const handleAddClick = () => {
        setCurrentCategory({ id: '', name: '' });
        setOpen(true);
    };

    const columns = [
        {
              name: "Name",
              center: true,
              selector: (row) => row.masterCategoryName,
              cell: (row) => {
                return (
                  <>
                    <div>{Switch == "master"?row.masterCategoryName:row.category_name}</div>
                  </>
                );
              },
            },
            {
              name: "Id",
              center: true,
              selector: (row) => Switch == "master"?row.masterCategoryId:row.category_id,
            },
            // {
            //   name: "Action",
            //   center: true,
            //   selector: (row) => {
            //     return (
            //       <>
            //         <div className="d-flex">
            //           <div>
            //             {/* <MdPlaylistAdd
            //               size={22}
            //               color="green"
            //               className="mouse-pointer"
            //               onClick={() => {
            //                 setModalShow((state) => !state);
            //               }}
            //             /> */}
            //           </div>
            //           <div style={{cursor:'pointer'}}>
            //         {/* <MdEdit
            //           size={22}
            //           color="var(--primary1)"
            //           className="mouse-pointer"
            //           onClick={() => {
            //             setSelectedRowData(row)
            //             setSelectedRow(row.user_id);
            //             setAddUpdateStoreModalIsOpen(true);
            //           }}
            //         /> */}
            //       </div>
            //         </div>
            //       </>
            //     );
            //   },
            // },
    ];

    return (
        <>
        <Container>
        <div className="d-flex justify-content-between align-items-center px-3">
      <h1 className="fs-3">Create Category</h1>
      <div className="d-flex gap-3"> 

      <Button
        className="fw-bold text-white"
        style={{ background: 'rgb(14, 41, 84)' }}
        variant="contained"
        color="primary"
        onClick={() =>{handleAddClick()}}
      >
        CREATE CATEGORY
      </Button>
        </div>
        <AddCategoryModal fetchCategories={fetchCategories} open={open} handleClose={()=>setOpen(false)} />
    </div>
    <div className="tabs mt-3 d-flex justify-content-between">
          <Button
            className={`tab-button ${Switch === 'master' ? 'active' : ''} `}
            onClick={() => setTab('master')}
            variant="outlined"
            color="primary"
          >
            Master Category
          </Button>
          <Button
            className={`tab-button ${Switch === 'sub' ? 'active' : ''}`}
            onClick={() => setTab('sub')}
            variant="outlined"
            color="secondary"
          >
            Sub Category
          </Button>
            </div>
        <DataTable
        columns={columns}
        responsive={true}
        fixedHeader={true}
        // fixedHeaderScrollHeight="300px"

        data={categories}
        // progressPending={loading}
        pagination
        paginationServer
        // paginationTotalRows={item_master_list ? item_master_list.totalCount : 1}
        // onChangeRowsPerPage={10}
        // onChangePage={handlePageChange}
      />
  </Container>

        </>
    );
};

export default GetAllCategory;