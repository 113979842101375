import React, { useState, useEffect } from "react"
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
// import { toast } from "@/components/ui/use-toast"

// Update this import based on your project structure
import DataService from "../../../services/requestApi"
import Swal from "sweetalert2";

function GetUserModal({
  show,
  onHide,
  userData,
  GetUser,
  setModalShow,
}) {
  const [countries, setCountries] = useState([])
  const { saasId, storeId } = JSON.parse(
    localStorage.getItem("User_data") || "{}"
  )
  const [userMasterData, setUserMasterData] = useState(userData || {
    user_name: "",
    mobile_number: "",
    ADD_PAYMENT_REFERENCE: "",
    business_name: "",
    password: "",
    store_name: "",
    city: "",
    state: "",
    base_currency: "",
    special_price: "",
    category_name: "",
    registerId: "",
    user_type: "",
    BAAR_CODE: "",
    CATEGORY_REQ: "",
    created_at: "",
    subscription_check: "",
    promo_case_check: "",
    invoice_template: "",
    storeId: storeId,
    saasId: saasId,
    country: "",
  })

  useEffect(() => {
    if (userData) {
      setUserMasterData((prevState) => ({
        ...prevState,
        user_name: userData.user_name || "",
        mobile_number: userData.mobile_number || "",
        password: userData.password || "",
        store_name: userData.store_name || "",
        storeId: userData.store_id || storeId,
        saasId: userData.saas_id || saasId,
        registerId: userData.register_id || "",
        city: userData.city || "",
        special_price: userData.special_price || "",
        category_name: userData.category_name || "",
        base_currency: userData.base_currency || "",
        state: userData.state || "",
        country: userData.country || "",
        BAAR_CODE: userData.baar_code || "",
        CATEGORY_REQ: userData.category_req || "",
        created_at: userData.created_at || "",
        subscription_check: userData.subscription_check || "",
        promo_case_check: userData.promo_case_check || "",
        invoice_template: userData.invoice_template || "",
        user_type: userData.user_type || "",
      }))
    }
  }, [userData])

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch("https://restcountries.com/v3.1/all")
        const data = await response.json()
        const countryOptions = data.map((country) => country.name.common)
        setCountries(countryOptions)
      } catch (error) {
        console.error(error)
      }
    }
    if (show) {
      fetchCountries()
    }
  }, [show])

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setUserMasterData((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleSubmitUserMaster = async () => {
    try {
      const response = await DataService.UpdateUserMaster(userMasterData, userData.user_id)
      if (response.status === 200) {
        GetUser()
        Swal.fire({
          title: "Success",
          description: "User Master Added Successfully",
        })
        setModalShow(false)
      }
    } catch (error) {
      console.error(error)
      Swal.fire({
        title: "Error",
        description: "Something went wrong",
        variant: "destructive",
      })
    }
  }

  return (
    <Dialog open={show} onClose={onHide} fullWidth maxWidth="md">
      <DialogTitle>User Master</DialogTitle>
      <DialogContent>
        <div style={{ display: "grid", gap: "1rem", padding: "1rem 0" }}>
          {[
            { label: "User Name", name: "user_name", type: "text" },
            { label: "Mobile Number", name: "mobile_number", type: "text" },
            { label: "Payment Reference", name: "ADD_PAYMENT_REFERENCE", type: "text" },
            { label: "Business Name", name: "business_name", type: "text" },
            { label: "Password", name: "password", type: "password" },
            { label: "Store Name", name: "store_name", type: "text" },
            { label: "City", name: "city", type: "text" },
            { label: "State", name: "state", type: "text" },
            { label: "Base Currency", name: "base_currency", type: "text" },
            { label: "Special Price", name: "special_price", type: "text" },
            { label: "Category Name", name: "category_name", type: "text" },
            { label: "Register ID", name: "registerId", type: "text" },
            { label: "User Type", name: "user_type", type: "text" },
            { label: "BAAR Code", name: "BAAR_CODE", type: "text" },
            { label: "Category Requirement", name: "CATEGORY_REQ", type: "text" },
            { label: "Created At", name: "created_at", type: "datetime-local" },
            { label: "Subscription Check", name: "subscription_check", type: "text" },
            { label: "Promo Case Check", name: "promo_case_check", type: "text" },
            { label: "Invoice Template", name: "invoice_template", type: "number" },
          ].map((field, index) => (
            field.name === "user_type" ? (
              <FormControl fullWidth key={index}>
                <InputLabel>User Type</InputLabel>
                <Select
                  name="user_type"
                  value={userMasterData.user_type || ""}
                  onChange={handleInputChange}
                  label="User Type"
                >
                  <MenuItem value="CASHIER">CASHIER</MenuItem>
                  <MenuItem value="MANAGER">MANAGER</MenuItem>
                  <MenuItem value="ALL">Admin</MenuItem>
                  <MenuItem value="RETAILER">RETAILER</MenuItem>
                </Select>
              </FormControl>
            ) : (
              <TextField
                key={index}
                label={field.label}
                name={field.name}
                type={field.type}
                value={userMasterData[field.name] || ""}
                onChange={handleInputChange}
                fullWidth
              />
            )
          ))}

          <FormControl fullWidth>
            <InputLabel>Country</InputLabel>
            <Select
              value={userMasterData.country || ""}
              onChange={(e) =>
                setUserMasterData((prev) => ({ ...prev, country: e.target.value }))
              }
            >
              {countries.map((country, index) => (
                <MenuItem key={index} value={country}>
                  {country}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="d-flex gap-3">
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmitUserMaster}
            fullWidth
          >
            Submit
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={onHide}
            fullWidth
          >
            Close
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default GetUserModal