import React, { useState, useEffect } from "react";
import axios from "axios";
import { FormGroup, TextField } from "@mui/material";
import AsyncSelect from "react-select/async";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import { BsCheck2 } from 'react-icons/bs';
// import Select from "react-select";
import {  MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import { BASEURL } from "../services/http-common";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Grid,
  Paper,
  TableFooter,
  Box,
  Fade,
  Backdrop,
  Modal,
  Autocomplete
} from '@mui/material';
import Swal from "sweetalert2";
const Purchase = () => {
  const { storeId, saasId } = JSON.parse(localStorage.getItem("User_data"));
  const [addItem, setAddItem] = useState(false);
  const [selectedParty, setParty] = useState(null);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [quantity, setQuantity] = useState("");
  const [supplierOptions, setSupplierOptions] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [itemDetail, setItemDetail] = useState({
    item_name: "",
    price: "0",
    quantity: "0",
    unit:""
  });
  const totalAmount = tableData.reduce((total, item) => total + parseFloat(item.price) , 0);

  const handelitemDetailChange = (e) => {
    const { name, value } = e.target;
    setItemDetail((prevState) => ({
     ...prevState,
      [name]: value
    }));
  };
  const handlePartyChange = (event) => {
    console.log("value: ", event.target.value)
    setParty(event.target.value);
  };
  const handleAddMaterialDetail = () => {
    console.log("itemDetail: ", itemDetail);
    if (itemDetail.item_name && itemDetail.price && itemDetail.quantity && itemDetail.unit) {
      const materialData = {
         item_name: itemDetail.item_name,
         price: itemDetail.price,
         productQty: itemDetail.quantity,
         uom: itemDetail.unit,
      };
      setTableData((prevTableData) => [...prevTableData, materialData]);
      setItemDetail({
        item_name: "",
        price: "",
        quantity: "",
        unit:""
      });
    }
  };

  const makeApiCall = async (url, payload) => {
    try {
      const response = await axios.post(url, payload);
      return response.data;
    } catch (error) {
      console.error("API Error:", error);
      throw error;
    }
  };

  const handleSupplierChange = async (selectedSupplier) => {
    try {
      const response = await makeApiCall(
        "https://posprdapi.photonsoftwares.com/prod/api/v1/inventory-master/inventory",
        {
          supplier_name: selectedSupplier.value,
          saas_id: "24",
          store_id: "24001",
        }
      );
      console.log("Inventory API Response:", response);
    } catch (error) {
      console.error("Error fetching inventory data:", error);
    }
  };

  const handleSave = async () => {
    try {
      const purchaseResponse = await axios.post(
        `${BASEURL.ENDPOINT_URL}/purchase/create`,
        {
          saas_id: saasId,
          store_id: storeId,
          supplier_name: selectedParty?.values,
          supplier_id: selectedParty?.id,
          item_list: tableData,
        }
      );
      console.log("Purchase API Response:", purchaseResponse);
      if(purchaseResponse.data.status){
        Swal.fire({
          title: "Purchase Saved Successfully",
          icon: "success",
          text: "Purchase has been saved successfully.",

        })
      }
      // const inventoryResponse = await makeApiCall(
      //   `${BASEURL.ENDPOINT_URL}/inventory-master/inventory`,
      //   {
      //     supplier_name: selectedParty.values,
      //     saas_id: saasId,
      //     store_id: storeId,
      //     // Add other payload properties as needed
      //   }
      // );
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };



  const getBahikhataPartyDropdown = async () => {
    
    try {
      const response = await axios.get(`${BASEURL.ENDPOINT_URL}/supplier/get-party-name/${saasId}/${storeId}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    // Fetch bahikhata party dropdown data using axios
    const fetchPartyDropdown = async () => {
      try {
        const data = await getBahikhataPartyDropdown();
        // Handle data as needed, e.g., update state
        console.log("Party Names:",data.data)
        setSupplierOptions(data.data);
      } catch (error) {
        // Handle errors
        console.error("Error fetching party dropdown:", error);
      }
    };

    fetchPartyDropdown();
  }, []);



  

  const loadOptions = async (inputValue, callback) => {
    try {
      // Perform your API call here with the inputValue
      const { storeId, saasId } = JSON.parse(localStorage.getItem("User_data"));
      const response = await axios.get(`http://103.139.59.207:8089/prod/api/v1/search/get-result/${storeId}/${saasId}/${inputValue}`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
  
      const jsonData = response.data;
      console.log("SEARCH VALUE", jsonData);
  
      if (jsonData && jsonData.status === true) {
        // Map the received data to the format expected by AsyncSelect
        const options = jsonData.data.map((item) => ({
          value: item.item_id, // replace with the actual property you want to use as value
          label: item.item_name, // replace with the actual property you want to use as label
        }));
  
        callback(options);
      } else {
        console.error("Error fetching data:", jsonData.message);
        callback([]);
      }
    } catch (error) {
      console.error("Error:", error);
      callback([]);
    }
  };
  const [allinventory, setAllInventoryData] = useState([])
  const [search , setSearch] = useState("")
  const [loading, setLoading] = useState(false)
    const getAllInventoryList = (search) => {
      setLoading(true)
      axios
        .get(
          `${BASEURL.ENDPOINT_URL}/search/get-result/${storeId}/${saasId}/${search}`
        )
        .then((res) => {
          if (res.status === 200) {
            res.data.data.forEach((el) => {
              el["value"] = el.item_id;
              el["label"] = el.item_name;
            });
            setAllInventoryData(res.data.data);
            setLoading(false)
          }
        })
        .catch((err) => {
          setLoading(false)
          console.log(err)});
    };
     
    useEffect(() => {
      if(search){
        getAllInventoryList(search);
      }
    }, [search]);
  return (
    <>
      <div className="" style={{ height: "fit-content" }}>
        <div className="row d-flex justify-content-center">
          <div className="col-lg-5 col-md-10 col-sm-12 px-3 w-75" style={{ boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.25)", borderRadius: "4px", background: "white", color: "black", border: "0px" }}>
            <form className="form-box">
              <div className="d-flex mt-3">
                <Link
                  to="/"
                  type="submit"
                  className="text-decoration-none"
                  style={{ color: "black" }}
                >
                  <i className="fa-solid fa-chevron-left mt-1"></i>
                </Link>
                <h4 style={{ fontFamily: "bold", marginLeft: "10px" }}>Add Purchase</h4>
              </div>
              {/* <TextField
                type="text"
                className="form-control my-2"
                id="bill-number"
                size="small"
                label="Bill Number"
                value={billNumber}
                onChange={(e) => setBillNumber(e.target.value)}
              /> */}
              {/* <div className="container p-0">
                <div className="input-group">
                  <input
                    type="date"
                    className="form-control"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                  />
                </div>
              </div> */}
              {/* <TextField
                type="text"
                className="form-control my-2"
                id="customer-name"
                size="small"
                label="Search by Phone Number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              /> */}
              <FormGroup>
              <Grid item xs={12} md={8}>
      <FormControl fullWidth>
        <InputLabel>Select Party</InputLabel>
        <Select
          value={selectedParty}
          onChange={handlePartyChange}
          label="Select Party"
        >
          {supplierOptions&& supplierOptions.map((item)=>{return(<MenuItem value={item}><em>{item.values}</em></MenuItem>)})}
         
        </Select>
      </FormControl>
      </Grid>
                {/* <div styles={{ zIndex: 999 }}>
                <Select
        required={true}
        placeholder="Select Party"
        options={supplierOptions}
        onChange={(selected) => setSelectedParty(selected)}
      />
                </div> */}
              </FormGroup>
              <TableContainer style={{ height: "200px", marginBottom:"10px" }} component={Paper}>
            <Table stickyHeader className="table-white table-striped  h-10 ">
              <TableHead>
                <TableRow className="text-nowrap">
                  <TableCell align="center">Material Name</TableCell>
                  <TableCell align="center">Purchase Qty</TableCell>
                  <TableCell align="center">Cost per Qty </TableCell>
                  <TableCell align="center">Purchase Unit</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.length > 0 ? (
                  tableData.map((el, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">{el.item_name}</TableCell>
                      <TableCell align="center">{el.productQty}</TableCell>
                      <TableCell align="center">{el.price}</TableCell>
                      <TableCell align="center">{el.uom}</TableCell>
                      {/* <TableCell align="center">{el.cost_of_material}</TableCell>
                      <TableCell align="center">{el.uom}</TableCell> */}
                      <TableCell align="center">
                        <BsCheck2 />
                      </TableCell>
                    </TableRow>
                 ))
                ) : null} 
                <TableRow>
                  <TableCell align="center">
                  <Autocomplete
      options={allinventory}
      getOptionLabel={(option) => option.label || ""} // Determine label display
      value={allinventory?.find((opt) => opt.label === itemDetail.item_name) || null}
      onChange={(event, newValue) => {
        console.log(newValue);
        handelitemDetailChange({
          target: { name: "item_name", value: newValue?.label || "" },
        });
      }}
      onInputChange={(event, newInputValue) => {
        // console.log(newInputValue)
        setSearch(newInputValue); // Update input value for API call
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          name="item_name"
          placeholder="Search Item"
          className="form-control"
          style={{
            width: "300px",
          }}
          aria-describedby="nameHelp"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <span>Loading...</span> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      isOptionEqualToValue={(option, value) => option.label === value.label}
    />
    {/* <TextField
                      type="text"
                      name='item_name'
                      value={itemDetail.item_name}
                      placeholder="Item Name"
                      onChange={handelitemDetailChange}
                      className="form-control"
                      aria-describedby="costHelp"
                    /> */}
                  </TableCell>
                 
                  <TableCell align="center">
                    <TextField
                      type="text"
                      name='quantity'
                      value={itemDetail.quantity}
                      placeholder="Quantity"
                      onChange={handelitemDetailChange}
                      className="form-control "
                      aria-describedby="costHelp"
                    />
                  </TableCell>
                  <TableCell align="center">
                    <TextField
                      type="text"
                      name='price'
                      value={itemDetail.price}
                      placeholder="Cost"
                      onChange={handelitemDetailChange}
                      className="form-control"
                      aria-describedby="costHelp"
                    />
                  </TableCell>
                  <TableCell align="center">
                  <FormControl fullWidth className="form-control ">
          <InputLabel id="demo-simple-select-label">Unit</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name='unit'
            value={itemDetail.unit}
            label="Age"
            onChange={handelitemDetailChange}
          >
            <MenuItem value="Kg">KG</MenuItem>
            <MenuItem value="Gm">Gm</MenuItem>
            <MenuItem value="Ml">Ml</MenuItem>
            <MenuItem value="Lt">Lt</MenuItem>
          </Select>
        </FormControl>
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      variant="contained"
                      color="warning"
                      onClick={handleAddMaterialDetail}
                    >
                      Add
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
              <TableFooter>
      <TableRow>
        <TableCell colSpan={2} align="right">
          <strong>Total Cost</strong>
        </TableCell>
        <TableCell align="center">
          {/* Calculate total cost dynamically from tableData */}
          {totalAmount /* Replace with your total calculation */}
        </TableCell>
        <TableCell />
      </TableRow>
    </TableFooter>
            </Table>
          </TableContainer>

              {/* <div
                style={{ boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.25)", borderRadius: "4px", background: "white", color: "black", border: "0px", width: "100%" }}
                onClick={() => setAddItem((state) => !state)}
              >
                <button
                  className="btn btn-outline-primary mt-3"
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "30px",
                    backgroundColor: "white",
                    color: "silver",
                    border: "aliceblue"
                  }}
                >
                  <AiOutlinePlusCircle />
                  Add Item (Optional)
                </button>
              </div> */}
              <div>
                
              </div>
              <div className="mt-5 d-flex justify-content-center">
                <button
                  type="button"
                  className="btn btn-primary btn-lg btn-block"
                  onClick={handleSave}
                  style={{
                    backgroundColor: "var(--Acc-1, #457FD4)",
                    outline: "none",
                    border: "none",
                    fontSize: "14px",
                    padding: "10px 140px",
                    borderRadius: "10px",
                    color: "#fff",
                  }}
                >
                  Save
                </button>
              </div>
              <div className="mt-2 d-flex justify-content-center">
                <Link
                  to="/"
                  type="submit"
                  className="text-decoration-none"
                  style={{
                    color: "black",
                  }}
                >
                  Cancel
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Purchase;
