import React, { useState } from 'react';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
} from '@mui/material';
import DataService from '../../../services/requestApi';
export default function SaasSubmissionForm() {
  const [open, setOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [formData, setFormData] = useState({
    saas_id: '',
    saas_name: '',
    currency: '',
    state_date: '',
    end_date: '',
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await DataService.AddSaasMaster(formData);

      if (response.data.status) {
        setSnackbarMessage('SaaS added successfully!');
        setSnackbarOpen(true);
        handleClose();
      } else {
        throw new Error('Failed to add SaaS');
      }
    } catch (error) {
      setSnackbarMessage('Error adding SaaS. Please try again.');
      setSnackbarOpen(true);
    }
  };

  return (
    <div>
        <Button
        className="fw-bold text-white"
        style={{ background: 'rgb(14, 41, 84)' }}
        variant="contained"
        color="primary"
        onClick={handleClickOpen}
      >
        Create Saas
      </Button>
      {/* <Button variant="outlined" >
        Add New SaaS
      </Button> */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add New SaaS</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <DialogContentText>
              Please fill in the details to add a new SaaS.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              name="saas_id"
              label="SaaS ID"
              type="text"
              fullWidth
              variant="outlined"
              value={formData.saas_id}
              onChange={handleInputChange}
              required
            />
            <TextField
              margin="dense"
              name="saas_name"
              label="SaaS Name"
              type="text"
              fullWidth
              variant="outlined"
              value={formData.saas_name}
              onChange={handleInputChange}
              required
            />
            <TextField
              margin="dense"
              name="currency"
              label="Currency"
              type="text"
              fullWidth
              variant="outlined"
              value={formData.currency}
              onChange={handleInputChange}
              required
            />
            <TextField
              margin="dense"
              name="state_date"
              label="Start Date"
              type="date"
              fullWidth
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={formData.state_date}
              onChange={handleInputChange}
              required
            />
            <TextField
              margin="dense"
              name="end_date"
              label="End Date"
              type="date"
              fullWidth
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={formData.end_date}
              onChange={handleInputChange}
              required
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">Submit</Button>
          </DialogActions>
        </form>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </div>
  );
}
