import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "react-bootstrap";
import { MdDelete, MdEdit, MdPlaylistAdd } from "react-icons/md";
import DataTable from "react-data-table-component";

import Swal from "sweetalert2";
import StoreMasterModal from "./StoreMasterModal";
import UpdateStoremasterModal from "./UpdateStoremasterModal";
import SaasSubmissionForm from "./CreateSaas";

function StoreMaster({ data }) {
  const [modalShow, setModalShow] = useState(false);
  const [addUpdateStoreModalIsOpen, setAddUpdateStoreModalIsOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRowData, setSelectedRowData] = useState("");

  // const style={
  //     boxShadow:"4px 4px 10px 0px rgba(0, 0, 0, 0.25)",
  //     borderRadius:"4px",
  //     background:"white",
  //     color:"black",
  //     border:"0px"
  //   }
  const columns = [
    {
      name: "Store Name",
      center: true,
      selector: (row) => row.address,
      cell: (row) => {
        return (
          <>
            <div>{row.address}</div>
          </>
        );
      },
    },
    {
      name: "Store Id",
      center: true,
      selector: (row) => row.store_id,
    },
    // {
    //   name: "Register Id",
    //   center: true,
    //   selector: (row) => row.register_id,
    // },
    {
      name: "Country",
      center: true,
      selector: (row) => row.country,
    },
    {
      name: "Action",
      center: true,
      selector: (row) => {
        return (
          <>
            <div className="d-flex">
              <div>
                <MdPlaylistAdd
                  size={22}
                  color="green"
                  className="mouse-pointer"
                  onClick={() => {
                    setModalShow((state) => !state);
                  }}
                />
              </div>
              <div style={{cursor:'pointer'}}>
            <MdEdit
              size={22}
              color="var(--primary1)"
              className="mouse-pointer"
              onClick={() => {
                setSelectedRowData(row)
                setSelectedRow(row.user_id);
                setAddUpdateStoreModalIsOpen(true);
              }}
            />
          </div>
            </div>
          </>
        );
      },
    },
  ];

  return (
    <>
      <div>
        {" "}
        <Container>
          <StoreMasterModal
            show={modalShow}
            setModalShow={setModalShow}
            onHide={() => setModalShow(false)}
          />
          <div className="d-flex justify-content-between align-items-center px-3">
      <h1 className="fs-3">Create Store</h1>
      <div className="d-flex gap-3"> 

      <Button
        className="fw-bold text-white"
        style={{ background: 'rgb(14, 41, 84)' }}
        variant="contained"
        color="primary"
        onClick={() =>setModalShow((state) => !state)}
      >
        CREATE STORE
      </Button>
      <SaasSubmissionForm/>
        </div>
    </div>

          <DataTable
            columns={columns}
            responsive={true}
            fixedHeader={true}
            // fixedHeaderScrollHeight="300px"

            data={data}
            // progressPending={loading}
            pagination
            paginationServer
          />
        </Container>

        <UpdateStoremasterModal selectedRowData ={selectedRowData}selectedRow={selectedRow} show={addUpdateStoreModalIsOpen} onHide={()=>setAddUpdateStoreModalIsOpen(false)}/>
      </div>
    </>
  );
}

export default StoreMaster;
