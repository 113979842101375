import { useEffect, useState } from "react";
import { BASEURL } from "../../../services/http-common";
import { Modal } from "react-bootstrap";
import { BsArrowLeft } from "react-icons/bs";
import { Button, TextField, Grid } from "@mui/material";
import Swal from "sweetalert2";
import DataService from '../../../services/requestApi';

const AddItemModal = ({
  addUpdateItemModalIsOpen,
  setAddUpdateItemModalIsOpen,
  row,
  setFlag,
  flag,
  GetItemReport
}) => {
  const [itemName, setItemName] = useState("");
  const [itemPrice, setItemPrice] = useState("0");
  const [actualPrice, setActualPrice] = useState("0");
  const [taxPercentage, setTaxPercentage] = useState("0");
  const [specialDescription, setSpecialDescription] = useState("");
  const [concId, setConcId] = useState("");
  const [dept, setDept] = useState("");
  const [itemCode, setItemCode] = useState("");
  const [barcode, setBarcode] = useState("");
  const [subClass, setSubClass] = useState("");
  const [openingQty, setOpeningQty] = useState("0");
  const [discount, setDiscount] = useState("0");
  const [itemClass, setItemClass] = useState("");
  const [productCost, setProductCost] = useState("0");
  const [receivedQty, setReceivedQty] = useState("0");
  const [itemCategory, setItemCategory] = useState("");

  const [Category, setCategory] = useState([]);
  const [concept, setConcept] = useState([]);

  const { saasId, storeId } = JSON.parse(localStorage.getItem("User_data"));

  useEffect(() => {
    if (row) {
      setItemName(row.item_name);
      setItemPrice(row.price);
      setActualPrice(row.actual_price);
      setTaxPercentage(row.tax_percent);
      setSpecialDescription(row.special_description);
      setConcId(row.conc_id);
      setDept(row.dept);
      setItemCode(row.item_code);
      setBarcode(row.barcode);
      setSubClass(row.sub_class);
      setOpeningQty(row.opening_qty);
      setDiscount(row.discount);
      setItemClass(row.item_class);
      setProductCost(row.product_cost);
      setReceivedQty(row.received_qty);
      setItemCategory(row.category);
    }
  }, [row]);

  const handleUpdateItem = (e) => {
    e.preventDefault();
    const updatedItem = {
      item_name: itemName,
      item_code: itemCode,
      special_description: specialDescription,
      price: itemPrice,
      actual_price: actualPrice,
      product_cost: productCost,
      discount: discount,
      tax: taxPercentage,
      saas_id: saasId,
      store_id: storeId,
      category: itemCategory,
      barcode: barcode,
      conc_id: concId,
      dept: dept,
      item_class: itemClass,
      sub_class: subClass,
      opening_qty: openingQty,
      received_qty: receivedQty,
    };

    fetch(`${BASEURL.ENDPOINT_URL}/item/update-item/${row.item_id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedItem),
    })
      .then((response) => response.json())
      .then((result) => {
        GetItemReport();
        setFlag(!flag);
        setAddUpdateItemModalIsOpen(false);
        Swal.fire("Success", "Item Updated Successfully", "success");
      })
      .catch((error) => {
        console.error("Error updating item:", error);
        Swal.fire("Error", "Failed to update item", "error");
      });
  };

  const GetCategory = async () => {
    try {
      const response = await DataService.GetSubCategory(saasId, storeId);
      setCategory(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const GetConcept = async () => {
    try {
      const response = await DataService.GetConceptAll(saasId);
      setConcept(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (addUpdateItemModalIsOpen) {
      GetCategory();
      GetConcept();
    }
  }, [addUpdateItemModalIsOpen]);

  return (
    <Modal
      show={addUpdateItemModalIsOpen}
      onHide={() => setAddUpdateItemModalIsOpen(!addUpdateItemModalIsOpen)}
      dialogClassName="modal-90w" // Bootstrap class for wide modals
      fullscreen
    >
      <div className="modal-header">
        <BsArrowLeft
          onClick={() => setAddUpdateItemModalIsOpen(!addUpdateItemModalIsOpen)}
          className="mouse-pointer"
        />
        <h5 className="modal-title">Update Item</h5>
      </div>
      <div className="modal-body">
        <form onSubmit={handleUpdateItem}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField
                fullWidth
                margin="normal"
                label="Item Name"
                value={itemName}
                onChange={(e) => setItemName(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                margin="normal"
                label="Item Price"
                type="number"
                value={itemPrice}
                onChange={(e) => setItemPrice(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                margin="normal"
                label="Actual Price"
                type="number"
                value={actualPrice}
                onChange={(e) => setActualPrice(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                margin="normal"
                label="Tax Percentage"
                type="number"
                value={taxPercentage}
                onChange={(e) => setTaxPercentage(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                margin="normal"
                label="Special Description"
                value={specialDescription}
                onChange={(e) => setSpecialDescription(e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                margin="normal"
                label="Item Code"
                value={itemCode}
                onChange={(e) => setItemCode(e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                margin="normal"
                label="Barcode"
                value={barcode}
                onChange={(e) => setBarcode(e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                margin="normal"
                label="Opening Quantity"
                type="number"
                value={openingQty}
                onChange={(e) => setOpeningQty(e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                margin="normal"
                label="Discount"
                type="number"
                value={discount}
                onChange={(e) => setDiscount(e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                margin="normal"
                label="Product Cost"
                type="number"
                value={productCost}
                onChange={(e) => setProductCost(e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                select
                fullWidth
                margin="normal"
                value={concId}
                onChange={(e) => {
                  console.log("this come", e.target.value)
                  setConcId(e.target.value)}}
                label="Conc ID"
                SelectProps={{
                  native: true,
                }}
              >
                <option value="" disabled>
                  {" "}
                </option>
                {concept.map((el) => (
                  <option key={el.conc_id} value={el.conc_code}>
                    {el.conc_name}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={4}>
              <TextField
                select
                fullWidth
                margin="normal"
                value={itemCategory}
                onChange={(e) => setItemCategory(e.target.value)}
                label="Category"
                SelectProps={{
                  native: true,
                }}
              >
                <option value="" disabled>
                 {" "}
                </option>
                {Category.map((category) => (
                  <option key={category.category_name} value={category.category_name}>
                    {category.category_name}
                  </option>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <div className="gap-3" style={{ marginTop: "20px", display: "flex", justifyContent: "center" }}>
            <Button type="submit" variant="contained" color="primary">
              Update
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setAddUpdateItemModalIsOpen(false)}
            >
              Close
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default AddItemModal;
