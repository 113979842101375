import React from 'react'
import { useNavigate } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import Swal from 'sweetalert2';
import { BsArrowLeft } from 'react-icons/bs';
import { BASEURL } from '../services/http-common';
function AddSupplier({ open, onHide ,getPartyOptions}) {
    const { storeId, saasId } = JSON.parse(localStorage.getItem('Store_data'));
    const [supplierdata, searchSuppliers] = React.useState(
        {
        party_name: "",
          gstin: "",
          phone_number: "",
          gst_type: "",
          state: "",
          email: "",
          billing_address: "",
          opening_balance: "0",
          credit_limit_flag: false,
          creditLimitAmount: "0"
      }
      );

    const navigate = useNavigate()
    const handleInputChange = (e) => {
        const { name, value } = e.target
        searchSuppliers(prevState => ({
          ...prevState,
          [name]: value
        }))
      }
      const addsuplire = async (e) => {
        e.preventDefault();
        
        try {
          // Validation
          if (!supplierdata.party_name || supplierdata.party_name.trim().length < 3) {
            Swal.fire({
              title: 'Error',
              text: 'Please enter a valid name (at least 3 characters).',
              icon: 'error',
            });
            return;
          }
      
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          if (!supplierdata.email || !emailRegex.test(supplierdata.email)) {
            Swal.fire({
              title: 'Error',
              text: 'Please enter a valid email address.',
              icon: 'error',
            });
            return;
          }
      
          const phoneRegex = /^[0-9]{10}$/; // Assuming a 10-digit phone number
          if (!supplierdata.phone_number || !phoneRegex.test(supplierdata.phone_number)) {
            Swal.fire({
              title: 'Error',
              text: 'Please enter a valid 10-digit phone number.',
              icon: 'error',
            });
            return;
          }
      
          // Adding necessary data
          supplierdata['saas_id'] = saasId;
          supplierdata['store_id'] = storeId;
      
          // API call
          const response = await fetch(`${BASEURL.ENDPOINT_URL}/supplier/create`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(supplierdata),
          });
      
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
      
          const data = await response.json();
          console.log(data);
      
          Swal.fire({
            title: 'Supplier Added Successfully',
            icon: 'success',
            timer: 2000,
          });
      
          getPartyOptions(1);
          onHide();
        } catch (error) {
          console.error('Error:', error);
          Swal.fire({
            title: 'Error',
            text: 'Something went wrong while adding the supplier.',
            icon: 'error',
          });
        }
      };
      
  return (
    <div>
             <Modal isOpen={open} toggle={onHide} size="xl" className="modal-fullscreen">
        <ModalHeader toggle={onHide}>
          <div className="d-flex align-items-center gap-4">
            <BsArrowLeft onClick={onHide} className="mouse-pointer" />
            <p className="fw-bold mt-2">Create Supplier</p>
          </div>
        </ModalHeader>
        <ModalBody>
        <div className="mx-auto bg-white p-4 rounded shadow" style={{ maxWidth: '28rem', maxHeight: 'calc(100vh - 170px)', overflowY: 'auto' }}>
  <div className="d-flex align-items-center mb-3">
    <h2 className="fs-5 fw-semibold">Add Supplier</h2>
  </div>
  <form onSubmit={addsuplire}>
    <div className="mb-3">
      <input
        type="text"
        name="party_name"
        placeholder="Supplier Name"
        value={supplierdata.party_name}
        onChange={handleInputChange}
        className="form-control"
      />
    </div>
    <div className="d-flex gap-2 mb-3">
      <input
        type="text"
        name="gstin"
        placeholder="GSTIN"
        value={supplierdata.gstin}
        onChange={handleInputChange}
        className="form-control"
      />
      <input
        type="text"
        name="gst_type"
        placeholder="GST Type"
        value={supplierdata.gst_type}
        onChange={handleInputChange}
        className="form-control"
      />
    </div>
    <div className="mb-3">
      <input
        type="tel"
        name="phone_number"
        placeholder="Phone Number"
        value={supplierdata.phone_number}
        onChange={handleInputChange}
        className="form-control"
      />
    </div>
    <div className="mb-3">
      <input
        type="email"
        name="email"
        placeholder="Email"
        value={supplierdata.email}
        onChange={handleInputChange}
        className="form-control"
      />
    </div>
    <div className="mb-3">
      <input
        type="text"
        name="billing_address"
        placeholder="Billing Address"
        value={supplierdata.billing_address}
        onChange={handleInputChange}
        className="form-control"
      />
    </div>
    <div className="mb-3">
      <input
        type="text"
        name="state"
        placeholder="State"
        value={supplierdata.state}
        onChange={handleInputChange}
        className="form-control"
      />
    </div>
    <div className="d-flex gap-2 mb-3">
      <input
        type="text"
        name="opening_balance"
        placeholder="Opening Amount"
        value={supplierdata.opening_balance}
        onChange={handleInputChange}
        className="form-control"
      />
      <input
        type="text"
        name="creditLimitAmount"
        placeholder="Credit Limit Amount"
        value={supplierdata.creditLimitAmount}
        onChange={handleInputChange}
        className="form-control"
      />
    </div>
    <div className="d-flex justify-content-center gap-3 mt-3">
      <button
        type="submit"
        className="btn btn-success px-4 py-2"
      >
        Save
      </button>
      <button
        type="button"
        className="btn btn-secondary px-4 py-2"
      >
        Close
      </button>
    </div>
  </form>
</div>

    </ModalBody>
    </Modal>
    </div>
  )
}

export default AddSupplier
