import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {  Col } from "react-bootstrap";
import { BsArrowLeft } from 'react-icons/bs';
import { TextField } from '@mui/material';
import DataService from '../../../services/requestApi'
import Swal from 'sweetalert2';
const ClassupdateModal = ({concept,Department, open,setclassModal,row,GetAllClass}) => {
    const [ConName, setConName] = useState({
      class_name:"",
      conc_id:"",
      con_name:"",
      dept_id:"",
      dept_name:"",
      class_id:""
    })
    console.log("Department",Department)
    useEffect(() => {
      if(row){
        setConName({
          class_name:row.class_name,
          conc_id:row.conc_id,
          con_name:row.con_name,
          dept_name:row.dept_name,
          dept_id:row.dept_id,
          class_id:row.class_id
        })
  
      }
    }, [row])
//api call for update name 
  const UpadteConcept=async()=>{
  try {
    if(ConName.class_name && ConName.conc_id && ConName.dept_id && ConName.class_id){
      const response = await DataService.UpdateClassMaster(row.class_code,ConName)
      if(response.data.status){
        GetAllClass()
        setclassModal(false)
        Swal.fire({
          title:" Class Updated Successfully",
          icon:"success",
          timer:2000
        })
      }

    }else{
      Swal.fire({
        title:"Please fill All field",
        icon:"error",
        timer:1000
      })
    }
  } catch (error) {
    console.log("this is catch", error)
  }
  }
  
 
  const handleonchange=(e)=>{
   setConName({...ConName,[e.target.name]:e.target.value})
  }

  return (
    <div><Modal
    isOpen={open}
    toggle={() => setclassModal(!open)}
  >
    <ModalHeader>
      <BsArrowLeft
        onClick={() =>
            setclassModal(!open)
        }
        className="mouse-pointer"
      />{" "}
      Update Class *
    </ModalHeader>
    <ModalBody>
      <div className="row d-flex justify-content-center">
        <div className="">
          <form
            className="form-box"
            // onSubmit={handleAddItem}
            // encType="multipart/form-data"
            encType="Content-Type"
          >
            <div
              className="d-flex flex-col"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Col md={12}>
              
              </Col>
              <TextField
                size="small"
                type="text"
                className="form-control my-2"
                value={ConName.class_name}
                name='class_name'
                onChange={handleonchange }
                label="Class Name"
                multiline
                required
                rows={1}
              />
              <TextField
                size="small"
                type="text"
                className="form-control my-2"
                value={ConName.class_id}
                name='class_id'
                onChange={handleonchange }
                label="Class Id"
                multiline
                required
                rows={1}
              />
               <TextField
                                 select
                                 size="small"
                                 className="form-control my-2"
                                 value={ConName?.conc_id || ""}
                                 name="conc_name"
                                 onChange={(e) => {
                                   const selectedOption = e.target.value;
                                   // Find the selected option from the array of options
                                   const selected = concept.find(
                                     (el) => el.conc_code == selectedOption
                                   );
                                   if (selected) {
                                     console.log(
                                       "Selected Concept",
                                       selected,
                                       selectedOption,
                                       concept
                                     );
                                     setConName({
                                       ...ConName,
                                       conc_name: selected.conc_name,
                                       conc_id: selected.conc_code,
                                     });
                                   }
                                 }}
                                 required
                                 SelectProps={{
                                   native: true,
                                 }}
                               >
                                 <option value="">Select Concept</option>
                                 {concept&&concept?.map((el) => (
                                   <option key={el.conc_code} value={el.conc_code}>
                                     {el.conc_name}
                                   </option>
                                 ))}
                               </TextField>
                               <TextField
                    select
                    size="small"
                    className="form-control my-2"
                    value={ConName?.dept_id || ""}
                    name="dept_name"
                    onChange={(e) => {
                      const selectedOption = e.target.value;
                      // Find the selected option from the array of options
                      const selected = Department.find(
                        (el) => el.dept_code == selectedOption
                      );
                      console.log("Selected Concept", selected, selectedOption,Department);
                      if (selected) {
                        console.log(
                          "Selected Concept",
                          selected,
                          selectedOption,
                          Department
                        );
                        setConName({
                          ...ConName,
                          dept_name: selected.dept_name,
                          dept_id: selected.dept_code,
                        });
                      }
                    }}
                    required
                    SelectProps={{
                      native: true,
                    }}
                  >
                    <option value="">Select Department</option>
                    {Department&&Department?.map((el) => (
                      <option key={el.dept_code} value={el.dept_code}>
                        {el.dept_name}
                      </option>
                    ))}
                  </TextField>
            </div>

            <div className="mt-2">
              <button
              className=''
                type="button"
                style={{
                  backgroundColor: "rgb(46, 69, 175)",
                  outline: "none",
                  border: "none",
                  fontSize: "20px",
                  padding: "10px 20px",
                  borderRadius: "10px",
                  color: "#fff",
                }}
                onClick={UpadteConcept}
              >
                Update class
              </button>

          
              <span
                // to="/retailer-dashboard"
                onClick={() => {
                    setclassModal(!open);
                }}
                className="btn btn-primary mb-2"
                style={{
                  backgroundColor: "grey",
                  outline: "none",
                  border: "none",
                  marginLeft: "20px",
                  fontSize: "20px",
                  padding: "10px 20px",
                  borderRadius: "10px",
                  color: "#fff",
                }}
              >
                Close
              </span>
            </div>
          </form>
          {/* )} */}
        </div>
      </div>
    </ModalBody>
    <ModalFooter>{/* <h1>FOOTER</h1> */}</ModalFooter>
  </Modal></div>
  )
}

export default ClassupdateModal